import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Tooltip } from "reactstrap";

const footer = [
  {
    id: 1,
    title: "Firma",
    menu: [
      {
        id: 1,
        link: "/hakkimizda",
        subTitle: "Hakkımızda",
      },
      {
        id: 2,
        link: "/iletisim",
        subTitle: "İletişim",
      },
      {
        id: 3,
        link: "/muzik-lisans-belgesi",
        subTitle: "Müzik Lisans Belgesi",
      },
      {
        id: 4,
        link: "/lisans-alan-firmalar",
        subTitle: "Lisans Alan Firmalar",
      },
      {
        id: 5,
        link: "/sikca-sorulan-sorular",
        subTitle: "S.S.S",
      },
      {
        id: 6,
        link: "/tarifeler",
        subTitle: "Tarifeler",
      },
    ],
  },
  {
    id: 2,
    title: "Gizlilik",
    menu: [
      {
        id: 1,
        link: "/kisisel-verilerin-korunmasi-ve-islenmesi-politikasi",
        subTitle: "Kişisel Verilerin Korunması ve İşlenmesi Politikası",
      },
      {
        id: 2,
        link: "/kisisel-verilerin-korunmasi-ve-islenmesi-politikasi?t=4",
        subTitle: "Çerez Politikası",
      },
      {
        id: 3,
        link: "/kisisel-verilerin-korunmasi-ve-islenmesi-politikasi?t=2",
        subTitle: "Aydınlatma Metni ve Gizlilik Politikası",
      },
    ],
  },
];

const Footer: React.FC = () => {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <section className="bg-footer">
        <Container>
          <Row>
            <Col lg={6}>
              <div className="footer-item mt-4 mt-lg-0 me-lg-5">
                <h4 className="text-white mb-4">OTEL LİSANSLAMA</h4>
                <p className="text-white-50 text-justify">
                  MSF ve MÜZFED, 5846 sayılı Fikir ve Sanat Eserleri kanununa
                  göre kurulmuş olan Mesam, MSG, Müyap, Müyorbir, Müyabir,
                  Müzikbir ve TSMB meslek birliklerinin kurduğu müzik sektöründe
                  eser sahipleri, fonogram yapımcıları ve icracıların haklarını
                  koruyan federasyonlardır.
                </p>
              </div>
            </Col>
            {footer.map((footerdetails, key) => (
              <Col lg={3} xs={6} key={key}>
                <div className="footer-item mt-4 mt-lg-0">
                  <p className="fs-16 text-white mb-4">{footerdetails.title}</p>
                  {(footerdetails.menu || []).map((menuInner, key) => (
                    <ul className="list-unstyled footer-list mb-0" key={key}>
                      <li>
                        <Link to={menuInner.link}>
                          <i className="mdi mdi-chevron-right"></i>{" "}
                          {menuInner.subTitle}
                        </Link>
                      </li>
                    </ul>
                  ))}
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <div className="footer-alt">
        <Container>
          <Row className="d-flex align-items-center">
            <Col lg={6}>
              <p className="text-white-50 text-start mb-0">
                Her Hakkı Saklıdır
              </p>
            </Col>
            <Col
              lg={6}
              className="d-flex justify-content-end align-items-center"
            >
              <Link
                to="http://kuryaz.com"
                target="_blank"
                rel="noreferrer"
                id="kuryazLink"
              >
                <img
                  src={require("@otel-lisanslama/assets/images/logo/kurgu_logo.png")}
                  alt="Kurgu Yazılım"
                  width={50}
                  height={50}
                />
              </Link>
              <Tooltip isOpen={tooltipOpen} target="kuryazLink" toggle={toggle}>
                Kurgu Yazılım
              </Tooltip>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Footer;
