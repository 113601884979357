import axios from "@otel-lisanslama/libs/axios";
import { DefaultRootStateProps } from "@otel-lisanslama/types";
import getErrorMessage from "@otel-lisanslama/utils/get-error-message";
import { createSlice } from "@reduxjs/toolkit";
import fileDownload from "js-file-download";
import { toast } from "react-hot-toast";
import { dispatch } from "..";

const initialState: DefaultRootStateProps["application"] = {
  applications: {
    records: [],
    recordCount: 0,
    isLoading: false,
    error: null,
  },
  licensedCompany: {
    data: null,
    isLoading: false,
    error: null,
  },
  isLoading: false,
  data: null,
  error: null,
};

const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    applicationsLoading(state) {
      state.applications.error = null;
      state.applications.isLoading = true;
    },
    applicationsError(state, action) {
      state.applications.error = getErrorMessage(
        action.payload,
        "Başvuru listesi alınamadı!"
      );
      state.applications.isLoading = false;
    },
    applicationsSuccess(state, action) {
      state.applications.error = null;
      state.applications.isLoading = false;
      state.applications.recordCount = action.payload.recordCount;
      state.applications.records = action.payload.records;
    },
    resetApplications(state) {
      state.applications.error = null;
      state.applications.records = [];
      state.applications.recordCount = 0;
      state.applications.isLoading = false;
    },

    licensedCompanyError(state, action) {
      state.licensedCompany.error = getErrorMessage(
        action.payload,
        "Bir hata oluştu"
      );
      state.licensedCompany.isLoading = false;
    },
    licensedCompanyLoading(state, action) {
      state.licensedCompany.error = null;
      state.licensedCompany.isLoading = action.payload;
    },
    licensedCompanySuccess(state, action) {
      state.licensedCompany.error = null;
      state.licensedCompany.isLoading = false;
      state.licensedCompany.data = action.payload;
    },

    resetLicensedCompany(state) {
      state.licensedCompany.data = null;
      state.licensedCompany.isLoading = false;
      state.licensedCompany.error = null;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = getErrorMessage(action.payload, "Bir hata oluştu");
    },
    // LOADING
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    // DATA
    setData(state, action) {
      state.data = action.payload;
    },
    // RESET
    reset(state) {
      state.data = null;
      state.isLoading = false;
      state.error = null;
    },
  },
});

export default applicationSlice.reducer;

export function fetchApplications(
  page: number,
  pageSize: number,
  searchQuery?: string,
  onlyAccepted?: boolean
) {
  return async () => {
    dispatch(applicationSlice.actions.applicationsLoading());
    try {
      let url = `/application?pageSize=${pageSize}&page=${page}`;

      if (searchQuery && searchQuery.length > 0) {
        url += `&search=${searchQuery}`;
      }

      if (onlyAccepted === true) {
        url += `&filter={"OR":[{"hasMuzfedApproval":true, "hasMsfApproval": true}, {"hasAllSocieties":true}]}`;
      }

      let query = "[";
      query += `{"id":"desc"}`;
      query += "]";
      url += `&orderBy=${query}`;

      const response = await axios.get(url);
      dispatch(applicationSlice.actions.applicationsSuccess(response.data));
    } catch (error) {
      dispatch(applicationSlice.actions.applicationsError(error));
    }
  };
}

export function fetchApprovedApplications(
  page: number,
  pageSize: number,
  searchQuery?: string
) {
  return async () => {
    dispatch(applicationSlice.actions.applicationsLoading());
    try {
      let url = `/application/approved?pageSize=${pageSize}&page=${page}`;

      if (searchQuery && searchQuery.length > 0) {
        url += `&search=${searchQuery}`;
      }

      const response = await axios.get(url);
      dispatch(applicationSlice.actions.applicationsSuccess(response.data));
    } catch (error) {
      dispatch(applicationSlice.actions.applicationsError(error));
    }
  };
}

export function approveApplication(
  id: number,
  hasMuzfedApproval?: boolean,
  hasMsfApproval?: boolean,
  hasAllSocieties?: boolean
) {
  return async () => {
    dispatch(applicationSlice.actions.setLoading(true));
    try {
      const requestData: any = {
        hasMsfApproval,
        hasMuzfedApproval,
        hasAllSocieties,
      };

      typeof hasMsfApproval === "undefined" &&
        delete requestData["hasMsfApproval"];
      typeof hasMuzfedApproval === "undefined" &&
        delete requestData["hasMuzfedApproval"];
      typeof hasAllSocieties === "undefined" &&
        delete requestData["hasAllSocieties"];

      const response = await axios.patch(`/application/${id}`, requestData);
      dispatch(applicationSlice.actions.setLoading(false));
      dispatch(applicationSlice.actions.setData(response.data));
    } catch (error) {
      dispatch(applicationSlice.actions.setLoading(false));
      dispatch(applicationSlice.actions.hasError(error));
    }
  };
}

export function exportApplications() {
  return async () => {
    dispatch(applicationSlice.actions.setLoading(true));

    try {
      const response = await axios.get("/application/export", {
        responseType: "blob",
      });
      fileDownload(response.data, "Başvuru Listesi.xlsx");
      dispatch(applicationSlice.actions.setLoading(false));
    } catch (error) {
      dispatch(applicationSlice.actions.setLoading(false));
      dispatch(applicationSlice.actions.hasError(error));
      toast.error(getErrorMessage(error, "Bir hata oluştu"));
    }
  };
}

export function newLicensedCompany(data: any, callback?: any) {
  return async () => {
    dispatch(applicationSlice.actions.licensedCompanyLoading(true));
    try {
      const response = await axios.post("/application/new", {
        ...data,
        calculatedMUZFEDPrice: 0,
        calculatedMSFPrice: 0,
      });

      dispatch(applicationSlice.actions.licensedCompanySuccess(response.data));
      if (callback && typeof callback === "function") {
        callback(response.data);
      }
    } catch (ex) {
      dispatch(applicationSlice.actions.licensedCompanyError(ex));
    }
  };
}

export function resetLicensedCompany() {
  return () => dispatch(applicationSlice.actions.resetLicensedCompany());
}
