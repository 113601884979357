import axios from "@otel-lisanslama/libs/axios";
import { DefaultRootStateProps } from "@otel-lisanslama/types";
import getErrorMessage from "@otel-lisanslama/utils/get-error-message";
import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "..";

const initialState: DefaultRootStateProps["location"] = {
  cities: {
    records: [],
    recordCount: 0,
    isLoading: false,
    error: null,
  },
  addressProvinces: {
    records: [],
    recordCount: 0,
    isLoading: false,
    error: null,
  },
  userCompanyProvinces: {
    records: [],
    recordCount: 0,
    isLoading: false,
    error: null,
  },
  isLoading: false,
  data: null,
  error: null,
};

const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    citiesLoading(state) {
      state.cities.error = null;
      state.cities.isLoading = true;
    },
    citiesError(state, action) {
      state.cities.error = getErrorMessage(
        action.payload,
        "İl listesi alınamadı!"
      );
      state.cities.isLoading = false;
    },
    citiesSuccess(state, action) {
      state.cities.error = null;
      state.cities.isLoading = false;
      state.cities.recordCount = action.payload.recordCount;
      state.cities.records = action.payload.records;
    },
    resetCities(state) {
      state.cities.error = null;
      state.cities.records = [];
      state.cities.recordCount = 0;
      state.cities.isLoading = false;
    },

    addressProvincesLoading(state) {
      state.addressProvinces.error = null;
      state.addressProvinces.isLoading = true;
    },
    addressProvincesError(state, action) {
      state.addressProvinces.error = getErrorMessage(
        action.payload,
        "İlçe listesi alınamadı!"
      );
      state.addressProvinces.isLoading = false;
    },
    addressProvincesSuccess(state, action) {
      state.addressProvinces.error = null;
      state.addressProvinces.isLoading = false;
      state.addressProvinces.recordCount = action.payload.recordCount;
      state.addressProvinces.records = action.payload.records;
    },
    resetAddressProvinces(state) {
      state.addressProvinces.error = null;
      state.addressProvinces.records = [];
      state.addressProvinces.recordCount = 0;
      state.addressProvinces.isLoading = false;
    },

    companyProvincesLoading(state) {
      state.userCompanyProvinces.error = null;
      state.userCompanyProvinces.isLoading = true;
    },
    companyProvincesError(state, action) {
      state.userCompanyProvinces.error = getErrorMessage(
        action.payload,
        "İlçe listesi alınamadı!"
      );
      state.userCompanyProvinces.isLoading = false;
    },
    companyProvincesSuccess(state, action) {
      state.userCompanyProvinces.error = null;
      state.userCompanyProvinces.isLoading = false;
      state.userCompanyProvinces.recordCount = action.payload.recordCount;
      state.userCompanyProvinces.records = action.payload.records;
    },
    resetCompanyProvinces(state) {
      state.userCompanyProvinces.error = null;
      state.userCompanyProvinces.records = [];
      state.userCompanyProvinces.recordCount = 0;
      state.userCompanyProvinces.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = getErrorMessage(action.payload, "Bir hata oluştu");
    },
    // LOADING
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    // DATA
    setData(state, action) {
      state.data = action.payload;
    },
    // RESET
    reset(state) {
      state.data = null;
      state.isLoading = false;
      state.error = null;
    },
  },
});

export default locationSlice.reducer;

export function resetAddressProvinces() {
  return () => {
    dispatch(locationSlice.actions.resetAddressProvinces());
  };
}

export function resetCompanyProvinces() {
  return () => {
    dispatch(locationSlice.actions.resetCompanyProvinces());
  };
}

export function fetchCities(page: number, pageSize: number) {
  return async () => {
    dispatch(locationSlice.actions.citiesLoading());
    try {
      let url = `/city?pageSize=${pageSize}&page=${page}`;
      let query = "[";
      query += `{"name":"asc"}`;
      query += "]";
      url += `&orderBy=${query}`;

      const response = await axios.get(url);
      dispatch(locationSlice.actions.citiesSuccess(response.data));
    } catch (error) {
      dispatch(locationSlice.actions.citiesError(error));
    }
  };
}

export function fetchAddressProvinces(
  cityId: number,
  page: number,
  pageSize: number
) {
  return async () => {
    dispatch(locationSlice.actions.addressProvincesLoading());
    try {
      let url = `/province/${cityId}?pageSize=${pageSize}&page=${page}`;

      let query = "[";
      query += `{"name":"asc"}`;
      query += "]";
      url += `&orderBy=${query}`;

      const response = await axios.get(url);
      dispatch(locationSlice.actions.addressProvincesSuccess(response.data));
    } catch (error) {
      dispatch(locationSlice.actions.addressProvincesError(error));
    }
  };
}

export function fetchCompanyProvinces(
  cityId: number,
  page: number,
  pageSize: number
) {
  return async () => {
    dispatch(locationSlice.actions.companyProvincesLoading());
    try {
      let url = `/province/${cityId}?pageSize=${pageSize}&page=${page}`;
      let query = "[";
      query += `{"name":"asc"}`;
      query += "]";
      url += `&orderBy=${query}`;

      const response = await axios.get(url);
      dispatch(locationSlice.actions.companyProvincesSuccess(response.data));
    } catch (error) {
      dispatch(locationSlice.actions.companyProvincesError(error));
    }
  };
}
