import isNull from "lodash/isNull";

const getErrorMessage = (error: any, defaultMessage: string) => {
  if (isNull(error)) {
    return;
  }

  if (typeof error === "string") {
    return error;
  }
  if (typeof error === "undefined") {
    return defaultMessage;
  }
  if (
    error.response &&
    error.response.data &&
    error.response.data.error &&
    error.response.data.error.message
  ) {
    return error.response.data.error.message;
  }
  if (error.message) {
    return error.message;
  }
  return defaultMessage;
};

export default getErrorMessage;
