import { combineReducers } from "redux";
import applicationSlice from "./slices/application.slice";
import authSlice from "./slices/auth.slice";
import locationSlice from "./slices/location.slice";

const reducer = combineReducers({
  application: applicationSlice,
  auth: authSlice,
  location: locationSlice,
});

export default reducer;
