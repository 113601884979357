const TermsOfUseText = () => (
  <>
    <h3 className="h3 h3-responsive text-center mb-5">KULLANIM KOŞULLARI</h3>
    <p>
      <a
        rel="noopener noreferrer"
        href="https://www.otellisanslama.org"
        target="_blank"
        style={{ textDecoration: "underline" }}
      >
        https://www.otellisanslama.org
      </a>{" "}
      uzantılı internet sitesinde (bundan sonra kısaca "İNTERNET SİTESİ"
      denilecektir.) sunulan hizmetler MÜZFED Müzik Sektöründe Bağlantılı Haklar
      Federasyonu (bundan böyle kısaca, "MÜZFED" olarak anılacaktır) ve MSF
      Müzik Sektöründe Eser Sahipleri Federasyonu (bundan böyle kısaca, "MSF"
      olarak anılacaktır) tarafından müştereken sağlanmaktadır. "İNTERNET
      SİTESİ"nin yasal sahibi MÜZFED ve MSF olup, "İNTERNET SİTESİ" üzerinde her
      türlü kullanım ve tasarruf yetkisi MÜZFED ve MSF"e aittir
    </p>

    <p>
      İşbu kullanım koşullarını{" "}
      <a
        rel="noopener noreferrer"
        href="https://www.otellisanslama.org"
        target="_blank"
        style={{ textDecoration: "underline" }}
      >
        https://www.otellisanslama.org
      </a>{" "}
      uzantılı internet sitesi adına MÜZFED ve MSF gerektiği zaman
      değiştirebilir; ancak bu değişiklikler düzenli olarak "İNTERNET SİTESİ"nde
      yayınlanacak ve aynı tarihten itibaren geçerli olacaktır.
    </p>

    <p>
      SİTE hizmetlerinden yararlanan ve "İNTERNET SİTESİ"ne erişim sağlayan her
      gerçek ve tüzel kişi, MÜ-YAP ve MÜYORBİR tarafından işbu kullanım
      koşulları hükümlerinde yapılan her değişikliği, önceden kabul etmiş
      sayılmaktadır.
    </p>

    <p>
      <a
        rel="noopener noreferrer"
        href="https://www.otellisanslama.org"
        target="_blank"
        style={{ textDecoration: "underline" }}
      >
        https://www.otellisanslama.org
      </a>{" "}
      uzantılı internet sitesi adına MÜZFED ve MSF, bu "İNTERNET SİTESİ"nde yer
      alan veya alacak olan bilgileri, formları ve içeriği dilediği zaman
      değiştirme hakkını saklı tutmaktadır
    </p>

    <h3 className="h3 h3-responsive mt-5">Sözleşme Tanımları</h3>

    <p>
      Online Lisanslama Birimi: MÜ-YAP ve MÜYORBİR"in, kullacıların müzik
      lisansına daha kolay ve tek elden ulaşmasını sağlamak, işletmelerin
      sorunlarına daha kısa yoldan çözümler bulmak için bir arada kurup
      müştereken sahip oldukları "İNTERNET SİTESİ" dir. Bundan sonra MÜ-YAP ve
      MÜYORBİR birlikte Online Lisanslama Birimi olarak anılacaktır.
    </p>

    <p>
      <a
        rel="noopener noreferrer"
        href="https://www.otellisanslama.org"
        target="_blank"
        style={{ textDecoration: "underline" }}
      >
        https://www.otellisanslama.org
      </a>{" "}
      uzantılı internet sitesi: MÜZFED ve MSF"nin, kullacıların müzik lisansına
      daha kolay ve tek elden ulaşmasını sağlamak, otellerin sorunlarına daha
      kısa yoldan çözümler bulmak için bir arada kurup müştereken sahip
      oldukları "İNTERNET SİTESİ" dir.
    </p>

    <p>
      <b>İNTERNET SİTESİ:</b>{" "}
      <a
        rel="noopener noreferrer"
        href="https://www.otellisanslama.org"
        target="_blank"
        style={{ textDecoration: "underline" }}
      >
        https://www.otellisanslama.org
      </a>{" "}
      uzantılı internet sitesidir.
    </p>

    <p>
      <b>ÜYE:</b> MÜZFED ve MSF üyeleri meslek birliklerini ifade etmektedir.
    </p>

    <p>
      <b>KULLANICI:</b>{" "}
      <a
        rel="noopener noreferrer"
        href="https://www.otellisanslama.org"
        target="_blank"
        style={{ textDecoration: "underline" }}
      >
        https://www.otellisanslama.org
      </a>{" "}
      uzantılı internet sitesini ziyaret eden ve kullanan kişidir.
    </p>

    <p>
      <b>LİNK:</b> "İNTERNET SİTESİ" üzerinden bir başka internet sitesine,
      dosyalara, içeriğe veya başka bir internet sitesinden ""İNTERNET
      SİTESİ"ne, dosyalara ve içeriğe erişimi mümkün kılan bağlantıdır.
    </p>

    <p>
      <b>İÇERİK:</b> "İNTERNET SİTESİ"nde ve/veya herhangi bir başka internet
      sitesinde yayınlanan veya erişimi mümkün olan her türlü bilgi, dosya,
      resim, program, rakam, fiyat, vs. görsel, yazınsal ve işitsel imgelerdir.
    </p>

    <p>
      <b>İNTERNET SİTESİ KULLANIM KOŞULLARI:</b> "İNTERNET SİTESİ" vasıtasıyla
      sunulan genel veya kişiye özel nitelikteki hizmetlerden yararlanacak
      gerçek ve/veya tüzel kişilerle ONLINE LİSANSLAMA BİRİMİ arasında
      elektronik ortamda akdedilen işbu sözleşmedir
    </p>

    <p>
      <b>KİŞİSEL BİLGİ:</b> KULLANICILARIN kimliği, adresi, elektronik posta
      adresi, telefon numarası, IP adresi, kredi kartı bilgileri, konum, adres
      ve iletişim bilgileri veya "İNTERNET SİTESİ"nin hangi bölümlerini ziyaret
      ettiği, domain tipi, browser tipi, ziyaret tarihi, saati, vs. bilgilerdir.
    </p>

    <p>
      Hizmetlerin Kapsamı, ONLINE LİSANSLAMA BİRİMİ"nin, "İNTERNET SİTESİ"
      üzerinden müzik içeriği kullanan işletme ve kuruluşlara, 5846 sayılı Fikir
      ve Sanat Eserleri Kanunu mevzuatı kapsamında MÜ-YAP ve MÜYORBİR"den
      tarafından online olarak yazılı izin ve sertifika verilmesine ilişkin
      hizmetlerin sunulması ve ilişki faaliyetlerden ibarettir
    </p>

    <p>
      ONLINE LİSANSLAMA BİRİMİ, "İNTERNET SİTESİ" üzerinden sunacağı hizmetlerin
      kapsamını ve niteliğini belirlemekte tamamen serbest olup, hizmetlere
      ilişkin olarak yapacağı değişiklikleri "İNTERNET SİTESİ" nde
      yayınlamasıyla yürürlüğe koymuş addedilir.
    </p>

    <p>
      <b>İNTERNET SİTESİ</b>,{" "}
      <a
        rel="noopener noreferrer"
        href="https://www.otellisanslama.org"
        target="_blank"
        style={{ textDecoration: "underline" }}
      >
        https://www.otellisanslama.org
      </a>{" "}
      uzantılı internet sitesini ifade eder ve bu "İNTERNET SİTESİ" bünyesinde
      sunulacak hizmetlerden yararlanabilmek için "İNTERNET SİTESİ""nin ilgili
      bölümünün içeriğinde belirtilecek özellikleri belirlemekte tamamen serbest
      olup, özelliklere ilişkin olarak yapacağı değişikliklerin "İNTERNET
      SİTESİ"nde yayınlamasıyla yürürlüğe koymuş addedilir. Genel Hükümler
      "İNTERNET SİTESİ" üzerinden, kendi kontrolünde olmayan ve başkaca üçüncü
      kişilerin sahip olduğu ve işlettiği başka internet sitelerine ve/veya
      başka içeriklere link verilebilir. Bu linkler KULLANICI"lara ve ÜYE"lere
      yönlenme kolaylığı sağlamak amacıyla konmuş olup, herhangi bir internet
      sitesini veya o siteyi işleten kişiyi desteklememektedir. Link verilen
      internet sitesinin içerdiği bilgilere yönelik herhangi bir türde bir beyan
      veya garanti niteliği taşımamaktadır. "İNTERNET SİTESİ" üzerindeki linkler
      vasıtasıyla erişilen web siteleri ve içerikleri hakkında MÜZFED ve MSF’nin
      herhangi bir sorumluluğu yoktur ve bu internet sitelerin kullanımıyla
      doğabilecek zararlar, KULLANICI"ların ve ÜYE"lerin kendi
      sorumluluğundadır. MÜZFED ve MSF bu tür link verilen web sitelerine
      erişimi, kendi yazılı muvafakatine bağlayabilir.
    </p>

    <p>
      <a
        rel="noopener noreferrer"
        href="https://www.otellisanslama.org"
        target="_blank"
        style={{ textDecoration: "underline" }}
      >
        https://www.otellisanslama.org
      </a>
    </p>

    <p>
      <b>KULLANICILAR</b>, "İNTERNET SİTESİ"ndeki, yüklenmeye (download) ve/veya
      paylaşıma müsait dosya, bilgi ve belgelerin, virüslerden, wormlardan,
      truva atlarından, dialer programlarından spam, spyware veya bunlar gibi
      diğer her türlü kötü ve zarar verme amaçlı kodlardan veya materyallerden
      arındırılamamış olabileceğini ve bu hususlarda "İNTERNET SİTESİ"nin
      garanti vermediğini kabul etmektedir. Bu tip kötü ve zarar verme amaçlı
      programların, kodların veya materyallerin önlenmesi, veri
      giriş-çıkışlarının doğruluğu veya herhangi bir kayıp verinin geri
      kazanılması için gereken tüm yazılım ve donanım ihtiyaçlarını karşılamak,
      bakım ve güncellemelerini yapmak tamamen KULLANICI"nın ve ÜYE"nin
      sorumluluğundadır. Bu tür kötü amaçlı programlar, kodlar veya
      materyallerin sebep olabileceği, veri yanlışlıkları veya kayıplarından
      dolayı KULLANICI ve ÜYE"nin veya üçüncü kişilerin uğrayabileceği hiçbir
      zarardan MÜZFED ve MSF sorumlu değildir. Bu tür kötü amaçlı programlar,
      kodlar veya materyaller, veri yanlışlıkları veya kayıplarından dolayı
      KULLANICI"nın ve ÜYE"nin veya üçüncü kişilerin uğrayabileceği hiçbir
      zarardan ONLINE LİSANSLAMA BİRİMİ sorumlu değildir.
    </p>

    <p>
      MÜZFED ve MSF , işbu "İNTERNET SİTESİ" ve "İNTERNET SİTESİ" uzantısında
      mevcut her tür hizmet, ürün, kampanya, vs. bilgiler ve "İNTERNET SİTESİ"yi
      kullanma koşulları ile SİTE"de sunulan bilgileri önceden bir ihtara gerek
      olmaksızın değiştirme, "İNTERNET SİTESİ"yi ve içeriğini yeniden düzenleme,
      yayını durdurma ve/veya duraklatma hakkını saklı tutar. Değişiklikler,
      "İNTERNET SİTESİ"nde yayınlanmalarıyla yürürlüğe girerler. "İNTERNET
      SİTESİ"nin kullanımı ya da "İNTERNET SİTESİ"ne giriş ile bu değişiklikler
      de kabul edilmiş sayılır. Bu koşullar link verilen diğer internet
      sayfaları için de geçerlidir. MÜZFED ve MSF, sözleşmenin ihlali, haksız
      fiil, ihmal veya diğer sebepler neticesinde; işlemin kesintiye uğraması,
      hata, ihmal, kesinti, silinme, kayıp, işlemin veya iletişimin gecikmesi,
      bilgisayar virüsü, iletişim hatası, hırsızlık, imha veya izinsiz olarak
      kayıtlara girilmesi, değiştirilmesi veya kullanılması hususunda ve
      durumunda herhangi bir sorumluluk kabul etmez.
    </p>

    <p>
      "İNTERNET SİTESİ" dâhilinde üçüncü kişiler tarafından sağlanan
      hizmetlerden ve yayınlanan içeriklerden dolayı MÜZFED ve MSF "nin, iş
      birliği içinde bulunduğu kurumların, MÜZFED ve MSF çalışanlarının ve
      yöneticilerinin, sorumluluğu bulunmamaktadır.
    </p>

    <p>
      Herhangi bir üçüncü kişi tarafından sağlanan ve yayınlanan bilgilerin,
      içeriklerin, görsel ve işitsel imgelerin doğruluğu ve hukuka uygunluğunun
      taahhüdü bütünüyle bu eylemleri gerçekleştiren üçüncü kişilerin
      sorumluluğundadır. MÜZFED ve MSF, üçüncü kişiler tarafından sağlanan
      hizmetlerin ve içeriklerin güvenliğini, doğruluğunu ve hukuka uygunluğunu
      taahhüt etmemektedir.
    </p>

    <p>
      "İNTERNET SİTESİ"ni kullananlar, yalnızca hukuka uygun ve şahsi amaçlarla
      SİTE üzerinde işlem yapabilirler. KULLANICI"ların ve ÜYE"lerin, "İNTERNET
      SİTESİ" dâhilinde yaptığı her işlem ve eylemdeki hukuki ve cezai
      sorumlulukları kendilerine aittir. Her KULLANICI ve her ÜYE, MÜZFED ile
      MSF ve/veya başka bir üçüncü kişinin haklarına tecavüz teşkil edecek
      nitelikteki herhangi bir faaliyette bulunmayacağını taahhüt eder.
      KULLANICI ve ÜYE"lerin "İNTERNET SİTESİ" üzerindeki faaliyetleri nedeniyle
      üçüncü kişilerin uğradıkları veya uğrayabilecekleri zararlardan dolayı
      MÜZFED ile MSF "nin doğrudan ve/veya dolaylı hiçbir sorumluluğu yoktur.
    </p>

    <p>
      İşbu "İNTERNET SİTESİ" nin sahibi müştereken MÜZFED ve MSF"dir. Bu
      "İNTERNET SİTESİ"nde bulunan bilgiler, yazılar, resimler, markalar, slogan
      ve diğer işaretler ile sair sınaî ve fikri mülkiyet haklarına ilişkin
      bilgilerin korunmasına yönelik programlarla, sayfa düzeni ve "İNTERNET
      SİTESİ" nin sunumu MÜZFED ile MSF"nin ya da MÜZFED ile MSF "nin izin ve
      lisans aldığı kuruluşların mülkiyetindedir. İşbu "İNTERNET SİTESİ"ndeki
      bilgilerin ya da "İNTERNET SİTESİ" sayfalarına ilişkin her tür veri
      tabanı, internet sitesi, software-code"ların html kodu ve diğer kodlar vs.
      ile "İNTERNET SİTESİ" içeriğinde bulunan ürünlerin, tasarımların,
      resimlerin, metinlerin, görsel, işitsel vesaire imgelerin, video
      kliplerin, dosyaların, katalogların ve listelerin kısmen ya da tamamen
      kopyalanması, değiştirilmesi, yayınlanması, online ya da diğer bir medya
      kullanılmak suretiyle gönderimi, dağıtımı, satılması yasaktır. KULLANICI
      ve ÜYE, yukarıda sayılan ve bunlarla sınırlı olmayan "İNTERNET SİTESİ"
      yazılım, donanım ve içeriğini çoğaltmayacağını, kopyalamayacağını,
      dağıtmayacağını, işlemeyeceğini gerek bu eylemleri ile gerekse de başka
      yollarla MÜZFED ve MSF ile doğrudan ve/veya dolaylı olarak rekabete
      girmeyeceğini kabul ve taahhüt etmektedir. KULLANICI ve ÜYE, MÜZFED ile
      MSF hizmetlerini, MÜZFED ile MSF bilgilerini ve MÜZFED ile MSF "nin telif
      haklarına tâbi çalışmalarını yeniden satmak, işlemek, paylaşmak, dağıtmak,
      sergilemek veya başkasının
      <a
        rel="noopener noreferrer"
        href="https://www.otellisanslama.org"
        target="_blank"
        style={{ textDecoration: "underline" }}
      >
        https://www.otellisanslama.org
      </a>{" "}
      hizmetlerine erişmesi veya kullanmasına izin vermek hakkına sahip
      değildir. Bu sayfadaki bilgilerin kısmen kopyalanması, basılması,
      işlenmesi, dağıtılması, çoğaltılması, sergilenmesi ancak ticari olmayan
      kişisel ihtiyaçlar için ve MÜZFED ve MSF"in yazılı izni ile mümkündür.
    </p>

    <p>
      MÜZFED ve MSF, "İNTERNET SİTESİ" üzerinden KULLANICI"lar ve ÜYE"ler
      tarafından kendisine iletilen bilgileri ,İnternet Sitesi Kullanım
      Koşulları“ hükümleri doğrultusunda kullanabilir. Bu bilgileri işleyebilir,
      bir veri tabanı üzerinde tasnif edip muhafaza edebilir. MÜZFED ve MSF,{" "}
      <b>
        aynı zamanda; KULLANICI ve ÜYE"nin kimliği, adresi, elektronik posta
        adresi, telefon numarası, IP adresi, "İNTERNET SİTESİ" nin hangi
        bölümlerini ziyaret ettiği, domain tipi, browser tipi, ziyaret tarihi,
        saati vs bilgileri de istatistiki değerlendirme, kampanyaların
        duyurusunu yapma ve kişiye yönelik hizmetler sunma gibi amaçlarla
        kullanabilir.
      </b>{" "}
      KULLANICI"lara ve ÜYE"lere ait kişisel bilgiler, kanunla yetkili kılınan
      mercilerin talebi ve aşağıda sayılan haller hariç olmak üzere gerçek ve
      tüzel üçüncü kişilere açıklanmayacaktır. Kişisel bilgi ender olarak MÜZFED
      ile MSF için veya onun adına davranan üçüncü taraflara veya MÜZFED ile MSF
      "nin işi ile ilgili olanlara verilerin özgün olarak kullanım amaçlarını
      daha iyi işlemek ya da KULLANICI"ların ve ÜYE"lerin önerdiği amaçlara
      uygun olarak daha iyi hizmet verebilmek üzere verilir.
    </p>

    <p>
      İşbu "İNTERNET SİTESİ" Kullanım Koşulları dâhilinde MÜZFED ile MSF
      tarafından açıkça yetki verilmediği hallerde MÜZFED ile MSF; MÜZFED ile
      MSF hizmetleri, MÜZFED ile MSF bilgileri, MÜZFED ile MSF telif haklarına
      tâbi çalışmaları, MÜZFED ile MSF ticari markaları, MÜZFED ile MSF ticari
      görünümü veya bu SİTE vasıtasıyla sağladığı başkaca varlık ve bilgilere
      yönelik tüm haklarını saklı tutmaktadır. MÜZFED ile MSF, "İNTERNET
      SİTESİ"ne erişilmesi, "İNTERNET SİTESİ"nin ya da "İNTERNET SİTESİ"ndeki
      bilgilerin ve diğer verilerin programların vs. kullanılması sebebiyle,
      sözleşmenin ihlali, haksız fiil, ya da başkaca sebeplere binaen,
      doğabilecek doğrudan ya da dolaylı hiçbir zarardan sorumlu değildir.
      MÜZFED ile MSF, sözleşmenin ihlali, haksız fiil, ihmal veya diğer sebepler
      neticesinde; işlemin kesintiye uğraması, hata, ihmal, kesinti hususunda
      herhangi bir sorumluluk kabul etmez. Bu "İNTERNET SİTESİ"ne ya da link
      verilen diğer internet sitelerine erişilmesi ya da "İNTERNET SİTESİ"nin
      kullanılması ile MÜZFED ile MSF"nin, kullanım/ziyaret sonucunda,
      doğabilecek her tür sorumluluktan, mahkeme ve diğer masraflar da dahil
      olmak üzere her tür zarar ve talep hakkından ayrı kılındığı kabul
      edilmektedir.
    </p>

    <p>
      Devir: MÜZFED ile MSF, bu sözleşmeyi bildirimsiz olarak istediği zaman
      kısmen veya bütünüyle devredebilir. Ancak KULLANICI ve ÜYE bu sözleşmeyi
      veya herhangi bir kısmını başka bir tarafa devredemez. Bu türden bir devir
      girişimi geçersizdir.
    </p>

    <h3 className="h3 h3-responsive mt-5">Mücbir Sebepler</h3>
    <p>
      Hukuken mücbir sebep sayılan tüm durumlarda, MÜZFED ile MSF işbu, İNTERNET
      SİTESİ KULLANIM KOŞULLARI“nı geç ifa etmekten veya ifa etmemekten dolayı
      yükümlü değildir. Bu ve bunun gibi durumlar, MÜZFED ile MSF açısından,
      gecikme veya ifa etmeme veya temerrüt addedilmeyecek veya bu durumlar için
      MÜZFED ile MSF "nin herhangi bir tazminat yükümlülüğü doğmayacaktır.
      Uygulanacak Hukuk ve Yetki İşbu ,İNTERNET SİTESİ KULLANIM KOŞULLARI“ndan
      kaynaklanacak ihtilaflar Türk Hukuku"na tabidir ve İstanbul Merkez
      Mahkemeleri ve İcra Daireleri yetkilidir. MÜZFED’in ve MSF’nin KULLANICI
      ve ÜYE"nin bulunduğu ülkede dava açma hakkı saklıdır. Yürürlük ve Kabul
      İşbu ,İNTERNET SİTESİ KULLANIM KOŞULLARI“, "İNTERNET SİTESİ" içeriğinde
      ilan edildiği tarihte yürürlük kazanır. KULLANICI"lar ve ÜYE"ler, işbu
      sözleşme hükümlerini "İNTERNET SİTESİ"ni kullanmakla kabul etmiş
      olmaktadırlar. MÜZFED ile MSF, dilediği zaman işbu sözleşme hükümlerinde
      değişikliğe gidebilir, değişiklikler, sürüm numarası ve değişiklik tarihi
      belirtilerek "İNTERNET SİTESİ" üzerinden yayınlanır ve aynı tarihte
      yürürlüğe girer.
    </p>
  </>
);

export default TermsOfUseText;
