import PageHeader from "@otel-lisanslama/components/PageHeader";
import classNames from "classnames";
import { useState } from "react";
import {
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import ApplicationFormText from "../components/Texts/ApplicationFormText";
import ApplicationGeneralExplanationText from "../components/Texts/ApplicationGeneralExplanationText";
import ClarificationTermText from "../components/Texts/ClarificationTermText";
import CookiePolicyText from "../components/Texts/CookiePolicyText";
import DisposalPolicyText from "../components/Texts/DisposalPolicyText";
import ProtectionOfPersonalDataText from "../components/Texts/ProtectionOfPersonalData";
import TermsOfUseText from "../components/Texts/TermsOfUseText";
import { useSearchParams } from "react-router-dom";

const ProtectionOfPersonalData = () => {
  const [searchParams] = useSearchParams();
  const [basicActiveTab, setBasicActiveTab] = useState<string>(
    searchParams.get("t") || "1"
  );

  const basicTabChange = (tab: string) => {
    if (basicActiveTab !== tab) setBasicActiveTab(tab);
  };

  return (
    <>
      <PageHeader title="Kişisel Verilerin Korunması ve İşlenmesi Politikası" />
      <Container className="my-5">
        <Row>
          <Col lg={12}>
            <Nav className="nav-pills custom" role="tablist">
              <NavItem>
                <NavLink
                  className={classNames({ active: basicActiveTab === "1" })}
                  to="#"
                  onClick={() => {
                    basicTabChange("1");
                  }}
                  type="button"
                >
                  KİŞİSEL VERİLERİN KORUNMASI VE İŞLENMESİ POLİTİKASI
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="#"
                  className={classNames({ active: basicActiveTab === "2" })}
                  onClick={() => {
                    basicTabChange("2");
                  }}
                  type="button"
                >
                  AYDINLATMA METNİ VE GİZLİLİK POLİTİKASI
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="#"
                  className={classNames({ active: basicActiveTab === "3" })}
                  onClick={() => {
                    basicTabChange("3");
                  }}
                  type="button"
                >
                  KULLANIM KOŞULLARI
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="#"
                  className={classNames({ active: basicActiveTab === "4" })}
                  onClick={() => {
                    basicTabChange("4");
                  }}
                  type="button"
                >
                  ÇEREZ POLİTİKASI
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="#"
                  className={classNames({ active: basicActiveTab === "5" })}
                  onClick={() => {
                    basicTabChange("5");
                  }}
                  type="button"
                >
                  KİŞİSEL VERİLERİ SAKLAMA VE İMHA POLİTİKASI
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="#"
                  className={classNames({ active: basicActiveTab === "6" })}
                  onClick={() => {
                    basicTabChange("6");
                  }}
                  type="button"
                >
                  VERİ SORUMLUSUNA BAŞVURU GENEL AÇIKLAMALARI
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  to="#"
                  className={classNames({ active: basicActiveTab === "7" })}
                  onClick={() => {
                    basicTabChange("7");
                  }}
                  type="button"
                >
                  BAŞVURU FORMU
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent
              activeTab={basicActiveTab}
              className="p-3 border border-top-0 rounded-bottom text-muted"
            >
              <TabPane tabId="1">
                <div className="mb-0">
                  <ProtectionOfPersonalDataText />
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="mb-0">
                  <ClarificationTermText />
                </div>
              </TabPane>
              <TabPane tabId="3">
                <div className="mb-0">
                  <TermsOfUseText />
                </div>
              </TabPane>
              <TabPane tabId="4">
                <div className="mb-0">
                  <CookiePolicyText />
                </div>
              </TabPane>
              <TabPane tabId="5">
                <div className="mb-0">
                  <DisposalPolicyText />
                </div>
              </TabPane>
              <TabPane tabId="6">
                <div className="mb-0">
                  <ApplicationGeneralExplanationText />
                </div>
              </TabPane>
              <TabPane tabId="7">
                <div className="mb-0">
                  <ApplicationFormText />
                </div>
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ProtectionOfPersonalData;
