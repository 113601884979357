import PageHeader from "@otel-lisanslama/components/PageHeader";
import { Col, Container, Row } from "reactstrap";
import MusicLicenseImg from "@otel-lisanslama/assets/music-license.png";

const MusicLicense = () => {
  return (
    <>
      <PageHeader title="Müzik Lisans Belgesi" />
      <section className="section overflow-hidden">
        <Container>
          <Row className="align-items-center g-0">
            <Col lg={6}>
              <div className="section-title me-lg-5">
                <h6 className="sub-title">Müzik Lisans Belgesi</h6>
                <h2 className="title mb-4">
                  <span className="text-primary fw-bold">MÜZİK LİSANSI</span>{" "}
                  Nedir?
                </h2>
                <p className="text-muted">
                  Otelinizde müzik yayını yapıyor ve bu müziği müşterilerinize
                  iletiyorsanız telif hakkı ortaya çıkar. Müzik Lisans Belgesi,
                  Online Lisanslama Birimi ile yapacağınız müzik yayınları izin
                  sözleşmesidir ve işletmenizde yaptığınız müziği MSF ve MÜZFED
                  açısından yasal hale getirir.
                </p>
                <p className="text-muted">
                  Müzik Lisans Belgesi alarak, müziği yaratanlara katkıda
                  bulunur ve müziğin geleceği için bu insanları desteklemiş
                  olursunuz. Böylece eser sahibi, icracı ve müziği kaydedenlerin
                  sevdiğiniz müziği yapmaya devem etmesini sağlarsınız.
                </p>
                <p className="text-muted">
                  İşletmenizde radyo, TV ve dijital cihazlardan yaptığınız ve
                  türü ne olursa olsun bütün müzikler için lisans almanız
                  gerekir.
                </p>
                <p className="text-muted">
                  Müzik Lisans Belgesi bedeli otelinizin oda ve yıldız sayısına
                  göre değişiklik gösterir.
                </p>
              </div>
            </Col>
            <Col lg={6}>
              <div className="about-img mt-4 mt-lg-0">
                <img
                  src={MusicLicenseImg}
                  alt="Müzik Lisans Belgesi"
                  className="img-fluid rounded"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default MusicLicense;
