import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

interface AuthLayoutProps {
  children: React.ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      <div>
        <div className="main-content">
          <div className="page-content">
            <section className="bg-auth">
              <Container>
                <Row className="justify-content-center">
                  <Col xl={10} lg={12}>
                    {children}
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthLayout;
