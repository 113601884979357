import Loader from "@otel-lisanslama/components/Loader";
import AppLayout from "@otel-lisanslama/layouts/AppLayout";
import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import AuthRoutes from "./AuthRoutes";
import DashboardRoutes from "./DashboardRoutes";
import MainRoutes from "./MainRoutes";

const HomePage = React.lazy(() => import("@otel-lisanslama/pages/Home"));

const AppRoutes: React.FC = () => {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route
            path="/"
            element={
              <AppLayout subPage={false}>
                <HomePage />
              </AppLayout>
            }
          />
          <Route path="/*" element={<MainRoutes />} />
          <Route path="auth/*" element={<AuthRoutes />} />
          <Route path="dashboard/*" element={<DashboardRoutes />} />
        </Routes>
      </Suspense>
    </>
  );
};

export default AppRoutes;
