import PageHeader from "@otel-lisanslama/components/PageHeader";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Col, Collapse, Container, Row } from "reactstrap";

const FAQ = () => {
  const [isCollapseFirst, setIsCollapseFirst] = useState(true);
  const toggleFirst = () => setIsCollapseFirst(!isCollapseFirst);

  const [isCollapseSecond, setIsCollapseSecond] = useState(false);
  const toggleSecond = () => setIsCollapseSecond(!isCollapseSecond);

  const [isCollapseThird, setIsCollapseThird] = useState(false);
  const toggleThird = () => setIsCollapseThird(!isCollapseThird);

  const [isCollapseForth, setIsCollapseForth] = useState(false);
  const toggleForth = () => setIsCollapseForth(!isCollapseForth);

  const [isCollapseFifth, setIsCollapseFifth] = useState(false);
  const toggleFifth = () => setIsCollapseFifth(!isCollapseFifth);

  const [isCollapseSixth, setIsCollapseSixth] = useState(false);
  const toggleSixth = () => setIsCollapseSixth(!isCollapseSixth);

  const [isCollapseSeventh, setIsCollapseSeventh] = useState(false);
  const toggleSeventh = () => setIsCollapseSeventh(!isCollapseSeventh);

  const [isCollapseEighth, setIsCollapseEighth] = useState(false);
  const toggleEighth = () => setIsCollapseEighth(!isCollapseEighth);

  const [isCollapseNineth, setIsCollapseNineth] = useState(false);
  const toggleNineth = () => setIsCollapseNineth(!isCollapseNineth);

  const [isCollapseTenth, setIsCollapseTenth] = useState(false);
  const toggleTenth = () => setIsCollapseTenth(!isCollapseTenth);

  const [isCollapseEleventh, setIsCollapseEleventh] = useState(false);
  const toggleEleventh = () => setIsCollapseEleventh(!isCollapseEleventh);

  const [isCollapseTwelfth, setIsCollapseTwelfth] = useState(false);
  const toggleTwelfth = () => setIsCollapseTwelfth(!isCollapseTwelfth);

  const [isCollapseThirteenth, setIsCollapseThirteenth] = useState(false);
  const toggleThirteenth = () => setIsCollapseThirteenth(!isCollapseThirteenth);

  const [isCollapseFourteenth, setCollapseFourteenth] = useState(false);
  const toggleFourteenthh = () => setCollapseFourteenth(!isCollapseFourteenth);

  const [isCollapseFifteen, setCollapseFifteen] = useState(false);
  const toggleFifteen = () => setCollapseFifteen(!isCollapseFifteen);

  return (
    <>
      <PageHeader title="Sıkça Sorulan Sorular" />
      <section className="section">
        <Container>
          <div className="accordion accordion-flush faq-box">
            <Row>
              {/* 1 */}
              <Col md={12}>
                <div className="accordion-item mt-4 border-0">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button bg-light text-muted"
                      onClick={toggleFirst}
                      type="button"
                    >
                      Müzik Lisans Belgesi nedir?
                    </button>
                  </h2>
                  <Collapse isOpen={isCollapseFirst}>
                    <div className="accordion-body">
                      Otelinizde müzik yayını yapıyorsanız hak sahiplerinden
                      yazılı izin almanız gerekir. Müzik Lisans Belgesi de MSF
                      ve MÜZFED federasyonlarına üye meslek birliklerinin
                      repertuvarlarının kullanımı için alınması gereken bu izin
                      belgesidir ve işletmenizde yaptığınız müziği yasal hale
                      getirir.
                    </div>
                  </Collapse>
                </div>
              </Col>

              {/* 2 */}
              <Col md={12}>
                <div className="accordion-item mt-4 border-0">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button bg-light text-muted"
                      onClick={toggleSecond}
                      type="button"
                    >
                      Neden Müzik Lisans Belgesi almalıyım?
                    </button>
                  </h2>
                  <Collapse isOpen={isCollapseSecond}>
                    <div className="accordion-body">
                      Bir işletmede yapılacak müzik yayını / kullanımı için izin
                      almak yasal gerekliliktir. İzinsiz müzik kullanmak hak
                      ihlalidir. Müzik lisansı alarak ayrıca müziği yaratanlara
                      katkıda bulunur ve müziğin geleceği için bu insanları
                      desteklemiş olursunuz. Böylece müzik kültürümüzün
                      geliştirilmesine, müzik icracılarının ve müziği
                      kaydedenlerin sevdiğiniz müziği size ulaştırmalarına devem
                      etmelerini sağlarsınız.
                    </div>
                  </Collapse>
                </div>
              </Col>

              {/* 3 */}
              <Col md={12}>
                <div className="accordion-item mt-4 border-0">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button bg-light text-muted"
                      onClick={toggleThird}
                      type="button"
                    >
                      Müzik Lisans Belgesi aldığımda hangi müzikleri
                      yayınlayabilirim?
                    </button>
                  </h2>
                  <Collapse isOpen={isCollapseThird}>
                    <div className="accordion-body">
                      Müzik Lisans Belgesi’ne sahip olan bir işletme MSF ve
                      MÜZFED repertuvarında bulunan yerli ve yabancı milyonlarca
                      parçanın yayınını yapabilir. Konseptinize uygun ve
                      işletmenize değer katacak olan müzik tarzını belirlemekte
                      özgür olacaksınız.
                    </div>
                  </Collapse>
                </div>
              </Col>

              {/* 4 */}
              <Col md={12}>
                <div className="accordion-item mt-4 border-0">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button bg-light text-muted"
                      onClick={toggleForth}
                      type="button"
                    >
                      Müzik Lisans Belgesi için ne kadar ödemem gerekir?
                    </button>
                  </h2>
                  <Collapse isOpen={isCollapseForth}>
                    <div className="accordion-body">
                      Müzik Lisans Belgesi bedeli otelinizin oda ve yıldız
                      sayısına göre değişiklik gösterir. Kesin olarak ne kadar
                      ödemeniz gerektiğini sayfanın en üstünde bulunan LİSANS AL
                      butonuna basarak görebilirsiniz.
                    </div>
                  </Collapse>
                </div>
              </Col>

              {/* 5 */}
              <Col md={12}>
                <div className="accordion-item mt-4 border-0">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button bg-light text-muted"
                      onClick={toggleFifth}
                      type="button"
                    >
                      İşletmemde sadece TV veya radyo kullanıyorum. Neden lisans
                      alayım?
                    </button>
                  </h2>
                  <Collapse isOpen={isCollapseFifth}>
                    <div className="accordion-body">
                      İşletmenizde müzik yayınını hangi vasıta ile yaptığınız
                      fark etmez, yine de yasal izninizi almanız gerekir.
                      İşletmelerde müzik kullanımı / yayını Televizyon veya
                      radyo kullanılmak suretiyle yapılsa bile bunu
                      müşterilerinize ilettiğinizde de Müzik Lisans Belgesi
                      alması gerekir.
                    </div>
                  </Collapse>
                </div>
              </Col>

              {/* 6 */}
              <Col md={12}>
                <div className="accordion-item mt-4 border-0">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button bg-light text-muted"
                      onClick={toggleSixth}
                      type="button"
                    >
                      Zaten TV platformunu para verip alıyorum. Neden lisans
                      alayım?
                    </button>
                  </h2>
                  <Collapse isOpen={isCollapseSixth}>
                    <div className="accordion-body">
                      İşletmenizde yayınını yaptığınız TV platformlarına
                      ödediğiniz para, platformun yayınları size ulaştırması ve
                      sizin o yayınları kullanabilmeniz içindir. O platformların
                      hiçbiri size müzik yayını izni vermez / veremez. Bu durumu
                      TV platformunuz ile de görüşebilirsiniz. Türkiye’de müzik
                      yayınları ile alakalı izinleri sadece ilgili MSF ve
                      MÜZFED’den alabilirsiniz.
                    </div>
                  </Collapse>
                </div>
              </Col>

              {/* 7 */}
              <Col md={12}>
                <div className="accordion-item mt-4 border-0">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button bg-light text-muted"
                      onClick={toggleSeventh}
                      type="button"
                    >
                      İşletmemde müzik yayınını internet üzerinden yapıyorum.
                      Yine de izin almalı mıyım?
                    </button>
                  </h2>
                  <Collapse isOpen={isCollapseSeventh}>
                    <div className="accordion-body">
                      İşletmenizdeki müzik yayınını internet üzerinden youtube
                      gibi platformlardan, Fizy veya Spotify gibi uygulamalardan
                      da yapsanız yine de hak sahiplerini temsil eden MSF ve
                      MÜZFED’den izin almanız gerekmektedir.
                    </div>
                  </Collapse>
                </div>
              </Col>

              {/* 8 */}
              <Col md={12}>
                <div className="accordion-item mt-4 border-0">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button bg-light text-muted"
                      onClick={toggleEighth}
                      type="button"
                    >
                      Müziği daha önce satın aldıysam, neden bir lisans ücreti
                      ödemeliyim?
                    </button>
                  </h2>
                  <Collapse isOpen={isCollapseEighth}>
                    <div className="accordion-body">
                      CD'den ya da dijital kaynaklardan yani internet üzerinden
                      para ödeyerek satın aldığınız müzikler kişisel
                      kullanımlarınız içindir. Ancak bu müziği kendi dinlemeniz
                      dışında umuma iletirseniz, yani işletmenizde müzik yayını
                      yapmakta kullanırsanız Yasalar gereği müzik lisansına yine
                      de ihtiyacınız olacaktır.
                    </div>
                  </Collapse>
                </div>
              </Col>

              {/* 9 */}
              <Col md={12}>
                <div className="accordion-item mt-4 border-0">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button bg-light text-muted"
                      onClick={toggleNineth}
                      type="button"
                    >
                      MSF ve MÜZFED nedir?
                    </button>
                  </h2>
                  <Collapse isOpen={isCollapseNineth}>
                    <div className="accordion-body">
                      MSF ve MÜZFED, 5846 sayılı Fikir ve Sanat Eserleri
                      Kanunu’na göre kurulmuş, T.C. Kültür ve Turizm
                      Bakanlığı’na bağlı olarak müzik alanında faaliyet gösteren
                      meslek birlikleri olan Mesam, MSG, Mü-yap, Müyorbir,
                      Müyabir, Müzikbir ve TSMB’nin kurduğu müzik sektöründeki
                      eser sahiplerini, fonogram yapımcılarını ve icracıları
                      temsil eden federasyonlardır.
                    </div>
                  </Collapse>
                </div>
              </Col>

              {/* 10 */}
              <Col md={12}>
                <div className="accordion-item mt-4 border-0">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button bg-light text-muted"
                      onClick={toggleTenth}
                      type="button"
                    >
                      Param nereye gidiyor?
                    </button>
                  </h2>
                  <Collapse isOpen={isCollapseTenth}>
                    <div className="accordion-body">
                      Aldığınız müzik lisansı için ödediğiniz lisans bedeli MSF
                      ve MÜZFED tarafından kendi üyelerine dağıtılmaktadır.
                      Federasyonlarımız Bakanlığın idari ve mali denetimine
                      tabidirler. Ödediğiniz lisans bedellerinin üyelerimize
                      dağıtılması ile ilgili olarak da denetlenmektedir.
                    </div>
                  </Collapse>
                </div>
              </Col>

              {/* 11 */}
              <Col md={12}>
                <div className="accordion-item mt-4 border-0">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button bg-light text-muted"
                      onClick={toggleEleventh}
                      type="button"
                    >
                      Müzik Lisans Belgesi almadan müzik yayınına devam edersem
                      ne olur?
                    </button>
                  </h2>
                  <Collapse isOpen={isCollapseEleventh}>
                    <div className="accordion-body">
                      Otelinizde yaptığınız müzik kullanımları ve yayınları için
                      Müzik Lisans Belgesi almak kanunen zorunludur. Müzik
                      Lisans Belgesi alınmadan müzik yayınlarına devam etmeniz
                      kanuna aykırı hareket etmenize sebep olur. Bu durumda MSF
                      ve MÜZFED bütün yasal haklarını kullanarak işletmeniz için
                      hukuki işlem başlatabilir.
                    </div>
                  </Collapse>
                </div>
              </Col>

              {/* 12 */}
              <Col md={12}>
                <div className="accordion-item mt-4 border-0">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button bg-light text-muted"
                      onClick={toggleTwelfth}
                      type="button"
                    >
                      Müzik Lisans Belgesi alınmaması halinde hukuki yaptırımlar
                      nedir?
                    </button>
                  </h2>
                  <Collapse isOpen={isCollapseTwelfth}>
                    <div className="accordion-body">
                      MSF ve MÜZFED, kendi müzik repertuvarlarının izinsiz
                      kullanıldığının ve yayınlandığının tespiti halinde; izin
                      bedelinin üç katına kadar tazminat talep etmekte ve
                      Cumhuriyet Başsavcılıklarına şikâyet başvuruların
                      yapmaktadırlar. Bu durumda da sorumlular hakkında 1 ila 5
                      yıla kadar hapis cezası veya karşılığı adli para cezası
                      söz konusu olmaktadır.
                    </div>
                  </Collapse>
                </div>
              </Col>

              {/* 13 */}
              <Col md={12}>
                <div className="accordion-item mt-4 border-0">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button bg-light text-muted"
                      onClick={toggleThirteenth}
                      type="button"
                    >
                      MSF ve MÜZFED hakkında hangi merciden bilgi alabilirim?
                    </button>
                  </h2>
                  <Collapse isOpen={isCollapseThirteenth}>
                    <div className="accordion-body">
                      MSF ve MÜZFED hakkındaki tüm bilgileri T.C. Kültür ve
                      Turizm Bakanlığı Telif Hakları Genel Müdürlüğü’nün{" "}
                      <Link
                        to="https://www.telifhaklari.gov.tr/Meslek-Birlikleri-Meslek-Birlikleri-Hakkinda"
                        target="_blank"
                      >
                        Meslek Birlikleri Hakkında - Telif Hakları Genel
                        Müdürlüğü
                      </Link>{" "}
                      adresinden alabilirsiniz.
                    </div>
                  </Collapse>
                </div>
              </Col>

              {/* 14 */}
              <Col md={12}>
                <div className="accordion-item mt-4 border-0">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button bg-light text-muted"
                      onClick={toggleFourteenthh}
                      type="button"
                    >
                      www.otellisanslama.org adresinden lisans aldıktan sonra
                      diğer alan meslek birliklerinden de izin almak gerekir mi?
                    </button>
                  </h2>
                  <Collapse isOpen={isCollapseFourteenth}>
                    <div className="accordion-body">
                      <p>
                        Eser sahiplerinin ve radyo ve televizyon kuruluşları da
                        dahil olmak üzere bağlantılı hak sahiplerinin ürünleri
                        üzerindeki mali ve manevi hakları ile bu ürünlerden
                        yararlanma şartları, 5846 sayılı Fikir ve Sanat Eserleri
                        Kanunu’nda düzenlenmiş olup 2023/8 sayılı Genelge,
                        anılan hakları veya yararlanma koşullarını etkilememekte
                        veya bertaraf etmemektedir.
                      </p>
                      <p>
                        Bu kapsamda 2023/8 sayılı Genelge’nin uygulanmasına
                        yönelik olarak oluşturulan müzik lisans belgesi, sadece
                        müzik sektöründe faaliyet gösteren federasyonlara
                        ilişkin olup diğer sektörlerde faaliyet gösteren meslek
                        birliklerinin lisanslama faaliyetlerini
                        etkilememektedir.
                      </p>
                    </div>
                  </Collapse>
                </div>
              </Col>

              {/* 15 */}
              <Col md={12}>
                <div className="accordion-item mt-4 border-0">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button bg-light text-muted"
                      onClick={toggleFifteen}
                      type="button"
                    >
                      Aldığım Müzik Lisans Belgesini Kültür ve Turizm
                      Bakanlığına nasıl ulaştırabilirim?
                    </button>
                  </h2>
                  <Collapse isOpen={isCollapseFifteen}>
                    <div className="accordion-body">
                      <p>
                        Konaklama tesislerince alınan Müzik Lisans Belgesinin
                        Kültür ve Turizm Bakanlığının birimi olan Yatırım ve
                        İşletmeler Genel Müdürlüğüne iletilmesi gerekmekte olup
                        söz konusu işlem e-Devlet sistemi içindeki Turizm
                        İşletme Belgesi ve Turizm Yatırımı Belgesi İşlemleri
                        (TTBİS) uygulaması üzerinden gerçekleştirilecektir.
                        Uygulamaya girildiğinde en sağda “Dilekçe Talep Başvuru
                        İşlemleri” sekmesi bulunmakta alınan Müzik Lisans
                        Belgesinin PDF hali bu menü üzerinden yüklenerek
                        Bakanlığa ulaştırılabilir.
                      </p>
                    </div>
                  </Collapse>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default FAQ;
