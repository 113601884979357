import ScrolltoTop from "@otel-lisanslama/components/ScrollToTop/ScrollToTop";
import { AppLayoutProps } from "@otel-lisanslama/types";
import React, { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import DashboardNavBar from "../components/DashboardNavBar";

const Authenticated = React.lazy(
  () => import("@otel-lisanslama/components/Authenticated")
);

const DashboardLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <Authenticated>
      <DashboardNavBar />
      <div className="main-content">
        <div className="page-content">{children}</div>
        <ScrolltoTop />
      </div>
    </Authenticated>
  );
};

export default DashboardLayout;
