import { USER_INFO_STORAGE_KEY } from "@otel-lisanslama/constants";
import axios from "@otel-lisanslama/libs/axios";
import {
  AuthContextProps,
  AuthContextProvider,
  LoginResponse,
  UserIdentity,
} from "@otel-lisanslama/types/auth.types";
import React, { useCallback, useState } from "react";

const defaultUser: UserIdentity = {
  email: "",
  id: -1,
  name: "",
  avatar: "",
  avatarThumbnail: "",
  isActive: false,
  phoneNumber: "",
  lastLogin: "",
  cityId: -1,
  provinceId: -1,
};

const AuthContext = React.createContext<AuthContextProvider>({
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  isAuthenticated: () => Promise.resolve(),
  user: defaultUser,
});

const Provider: React.FC<AuthContextProps> = ({ children }) => {
  const [authUserInfo, setAuthUserInfo] = useState<UserIdentity>(
    JSON.parse(window.sessionStorage.getItem(USER_INFO_STORAGE_KEY) || "{}") ||
      defaultUser
  );

  const setIdentity = useCallback((params: UserIdentity) => {
    setAuthUserInfo(params);
    if (typeof window !== "undefined") {
      window.sessionStorage.setItem(
        USER_INFO_STORAGE_KEY,
        JSON.stringify(params)
      );
    }
  }, []);

  const login = useCallback(
    async (email: string, password: string) => {
      const response = await axios.post<LoginResponse>("/auth/login", {
        email,
        password,
      });
      setIdentity(response.data.user);
      return response;
    },
    [setIdentity]
  );

  const logout = useCallback(async () => {
    setAuthUserInfo(defaultUser);

    if (typeof window !== "undefined") {
      window.sessionStorage.getItem(USER_INFO_STORAGE_KEY) &&
        window.sessionStorage.removeItem(USER_INFO_STORAGE_KEY);
    }

    return await axios.post<boolean>("/auth/logout", {}, {});
  }, []);

  const isAuthenticated = useCallback(async () => {
    try {
      return await axios.get("/auth/checkToken");
    } catch (ex) {
      throw ex;
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        isAuthenticated,
        user: authUserInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, Provider as AuthProvider };
