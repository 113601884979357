import AboutUsImg from "@otel-lisanslama/assets/about-us.png";
import PageHeader from "@otel-lisanslama/components/PageHeader";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const About = () => {
  return (
    <>
      <PageHeader title="Hakkımızda" />
      <section className="section overflow-hidden">
        <Container>
          <Row className="align-items-center g-0">
            <Col lg={6}>
              <div className="section-title me-lg-5">
                <h6 className="sub-title">Hakkımızda</h6>
                <h2 className="title mb-4">
                  <span className="text-primary fw-bold">OTEL LİSANSLAMA</span>{" "}
                  Nedir?
                </h2>
                <p className="text-muted">
                  MSF ve MÜZFED, 5846 sayılı Fikir ve Sanat Eserleri kanununa
                  göre kurulmuş olan Mesam, MSG, Müyap, Müyorbir, Müyabir,
                  Müzikbir ve TSMB meslek birliklerinin kurduğu müzik sektöründe
                  eser sahipleri, fonogram yapımcıları ve icracıların haklarını
                  koruyan federasyonlardır.
                </p>
                <p className="text-muted">
                  MSF ve MÜZFED’in tüm iş ve işlemleri Kültür ve Turizm
                  Bakanlığı’nın idari ve mali denetimi altındadır. MSF ve
                  MÜZFED, konaklama tesislerinin Müzik Lisans Belgesi’ne daha
                  kolay ve tek elden ulaşmasını sağlamak, işletmelerin
                  sorunlarına daha kısa yoldan çözümler bulmak için bir araya
                  gelerek Online Lisanslama birimini kurdular.
                </p>
                <p className="text-muted">
                  Online Lisanslama birimi vasıtasıyla hem MSF hem de MÜZFED’den
                  tek tek Müzik Lisans Belgesi almanıza gerek kalmamış ve
                  çok daha kolay biçimde yasal müzik kullanımınız sağlanmıştır.
                </p>
                <div className="mt-3">
                  <Link to="/iletisim" className="btn btn-primary btn-hover">
                    İletişim{" "}
                    <i className="uil uil-angle-right-b align-middle"></i>
                  </Link>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <div className="about-img mt-4 mt-lg-0">
                <img
                  src={AboutUsImg}
                  alt="Hakkımızda"
                  className="img-fluid rounded"
                />
              </div>
            </Col>
          </Row>
        </Container>

        {/* <Container>
          <Row className="align-items-center g-0">
            <Col lg={12}>
              <div className="section-title me-lg-5">
                <p className="text-muted">
                  MSF ve MÜZFED, 5846 sayılı Fikir ve Sanat Eserleri kanununa
                  göre kurulmuş olan Mesam, MSG, Müyap, Müyorbir, Müyabir,
                  Müzikbir ve TSMB meslek birliklerinin kurduğu müzik sektöründe
                  eser sahipleri, fonogram yapımcıları ve icracıların haklarını
                  koruyan federasyonlardır. MSF ve MÜZFED’in tüm iş ve işlemleri
                  Kültür ve Turizm Bakanlığı’nın idari ve mali denetimi
                  altındadır. MSF ve MÜZFED, konaklama tesislerinin müzik
                  sertifikasına daha kolay ve tek elden ulaşmasını sağlamak,
                  işletmelerin sorunlarına daha kısa yoldan çözümler bulmak için
                  bir araya gelerek Online Lisanslama birimini kurdular. Online
                  Lisanslama birimi vasıtasıyla hem MSF hem de MÜZFED’den tek
                  tek sertifika almanıza gerek kalmamış ve çok daha kolay
                  biçimde yasal müzik kullanımınız sağlanmıştır.
                </p>
              </div>
            </Col>
          </Row>
        </Container> */}
      </section>
    </>
  );
};

export default About;
