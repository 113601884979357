import ScrolltoTop from "@otel-lisanslama/components/ScrollToTop/ScrollToTop";
import { AppLayoutProps } from "@otel-lisanslama/types";
import { FC, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";

const AppLayout: FC<AppLayoutProps> = ({ children, subPage }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div>
      {subPage && <NavBar />}
      <div className="main-content">
        <div className="page-content">{children}</div>

        {subPage && <Footer />}
        <ScrolltoTop />
      </div>
    </div>
  );
};

export default AppLayout;
