import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Collapse, Container, NavItem, NavbarToggler } from "reactstrap";

const NavBar = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [navClass, setNavClass] = useState<string>("");

  const toggle = () => setIsOpen(!isOpen);

  function scrollNavigation() {
    var scrollup = window.pageYOffset;
    if (scrollup > 0) {
      setNavClass("nav-sticky mt-0");
    } else {
      setNavClass("");
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });

  return (
    <>
      <nav
        className={
          "navbar navbar-expand-lg fixed-top sticky p-0 mt-0" + navClass
        }
        id="navigation"
      >
        <Container fluid className="custom-container">
          <div>
            <NavbarToggler
              className="me-3"
              type="button"
              onClick={() => toggle()}
            >
              <i className="mdi mdi-menu"></i>
            </NavbarToggler>
          </div>
          <Collapse
            isOpen={isOpen}
            className="navbar-collapse"
            id="navbarCollapse"
          >
            <ul className="navbar-nav mx-auto navbar-center">
              <NavItem>
                <Link className="nav-link" to="/">
                  Ana Sayfa
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to="/hakkimizda">
                  Hakkımızda
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to="/iletisim">
                  İletişim
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to="/muzik-lisans-belgesi">
                  Müzik Lisans Belgesi
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to="/lisans-alan-firmalar" reloadDocument>
                  Lisans Alan Firmalar
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to="/sikca-sorulan-sorular">
                  Sıkça Sorulan Sorular
                </Link>
              </NavItem>
              <NavItem>
                <Link className="nav-link" to="/tarifeler">
                  Tarifeler
                </Link>
              </NavItem>
            </ul>
          </Collapse>
        </Container>
      </nav>
    </>
  );
};

export default NavBar;
