import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Container,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  NavItem,
  NavbarToggler,
} from "reactstrap";

import useGetIdentity from "@otel-lisanslama/hooks/useGetIdentity";
import useLogout from "@otel-lisanslama/hooks/useLogout";

const DashboardNavBar = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [navClass, setNavClass] = useState<string>("");
  const identity = useGetIdentity();
  const logout = useLogout();

  const [userProfile, setUserProfile] = useState<boolean>(false);
  const dropDownuserprofile = () => setUserProfile((prevState) => !prevState);

  const toggle = () => setIsOpen(!isOpen);

  function scrollNavigation() {
    var scrollup = window.pageYOffset;
    if (scrollup > 0) {
      setNavClass("nav-sticky");
    } else {
      setNavClass("");
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", scrollNavigation, true);
  });

  return (
    <>
      <nav
        className={
          "navbar navbar-expand-lg mt-0 fixed-top sticky p-0 " + navClass
        }
        id="navigation"
      >
        <Container fluid className="custom-container">
          <div>
            <NavbarToggler
              className="me-3"
              type="button"
              onClick={() => toggle()}
            >
              <i className="mdi mdi-menu"></i>
            </NavbarToggler>
          </div>
          <Collapse
            isOpen={isOpen}
            className="navbar-collapse"
            id="navbarCollapse"
          >
            <ul className="navbar-nav mx-auto navbar-center">
              <NavItem>
                <Link className="nav-link" to="/dashboard/basvurular/liste" reloadDocument>
                  BAŞVURU LİSTESİ
                </Link>
              </NavItem>
            </ul>
          </Collapse>

          <ul className="header-menu list-inline d-flex align-items-center mb-0">
            <Dropdown
              onClick={() => setUserProfile(!userProfile)}
              isOpen={userProfile}
              toggle={dropDownuserprofile}
              className="list-inline-item"
            >
              <DropdownToggle
                to="#"
                className="header-item"
                id="userdropdown"
                type="button"
                tag="a"
                aria-expanded="false"
              >
                <span className="d-none d-md-inline-block fw-medium">
                  {identity.email}
                </span>
              </DropdownToggle>
              <DropdownMenu
                className="dropdown-menu-end"
                aria-labelledby="userdropdown"
                end
              >
                <li>
                  <Link className="dropdown-item" to="#!" onClick={logout}>
                    Çıkış Yap
                  </Link>
                </li>
              </DropdownMenu>
            </Dropdown>
          </ul>
        </Container>
      </nav>
    </>
  );
};

export default DashboardNavBar;
