import axios from "@otel-lisanslama/libs/axios";
import { AxiosInstance } from "axios";

const CSRF_TOKEN_HEADER = "CSRF-TOKEN";

export const fetchCsrfToken = async () => {
  const response = await axios.get<string>("/auth/csrf");
  return response.data;
};

export const addCsrfTokenInterceptor = (
  csrfToken: string,
  axiosInstance: AxiosInstance
) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      if (csrfToken) {
        config.headers[CSRF_TOKEN_HEADER] = csrfToken;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
};
