const ProtectionOfPersonalDataText = () => {
  return (
    <>
      <h3 className="h3 h3-responsive text-center mb-5">
        KİŞİSEL VERİLERİN KORUNMASI POLİTİKASI
      </h3>
      <h2 className="h2">Giriş</h2>
      <ol style={{ listStyleType: "decimal" }}>
        <li>Tanımlar</li>
        <li>Kapsam</li>
        <li>İşlenen Verilerin Niteliği</li>
        <li>Veri Koruma Sorumlusu</li>
        <li>Kişisel Verileri İşleme Amacı</li>
        <li>İşlemin Yasal Temeli</li>
        <li>Kişisel Verilerin Korunması Yöntemi</li>
        <li>İşlenen Kişisel Veri Türleri</li>
        <li>Kişisel Verilerin Aktarma Şartları</li>
        <li>Kişisel Verilerin Aktarma Şartları</li>
        <li>Aktarılan Kişisel ve Özel Nitelikli Veriler</li>
        <li>Diğer Veri Aktarım Nedenleri ve Yurtdışına Veri Aktarımı</li>
        <li>Veri Tutma Süresi</li>
        <li>İlgili Kişinin Hakları</li>
        <li>Kişisel Verilerin İşlenmesinde Geçerli İlkeler</li>
        <li>Veri Sorumlusuna Başvuru</li>
        <li>Veri Sorumlusunun Rolü ve Sorumlulukları</li>
        <li>Yetki Matrisi</li>
        <li>Bilgi Güvenliği Organizasyonu</li>
        <li>Bildirim Yükümlülüğü</li>
        <li>Politika Sürümleri</li>
      </ol>

      <h4 className="h4">Ek 1 - Üçüncü şahıslarla veri koruma sözleşmeleri</h4>
      <h4 className="h4">
        Ek 2 - Veri koruma ihlallerine ilişkin yükümlülüklerin bildirilmesi
      </h4>
      <h4 className="h4">Ek 3 - Kişisel veri saklama süreleri</h4>

      <h3 className="h3">GİRİŞ</h3>

      <p>
        <b>
          MÜZFED Müzik Sektöründe Bağlantılı Haklar Federasyonu (kısaca
          “MÜZFED”) ve MSF Müzik Sektöründe Eser Sahipleri Federasyonu (kısaca
          “MSF”) ORTAK VERİ SORUMLUSUDUR. (VERİ SORUMLUSU)
        </b>
        , <a href="www.otellisanslama.org">www.otellisanslama.org</a> uzantılı
        internet sitesi sonuçlanma nedeni ile kişisel verilerinde yüksek düzeyde
        koruma sağlamayı taahhüt eder.
      </p>
      <p>
        Kişisel verilerin gizliliği / korunması konusundaki mevcut mevzuatın
        temel ilkeleri gözden geçirilmiş ve politikamız mevzuatla da uyarlı bir
        şekilde hazırlanmıştır. Politikayı mevzuata uygun olarak hazırlarken
        sektörde verdiğimiz hizmetler de göz önünde bulundurulmuştur.
      </p>

      <p>
        <b>KİŞİSEL VERİLERİN KORUNMASI POLİTİKASI</b> 6698 sayılı yasaca
        düzenlenmesi zorunlu olan bir disiplin olup, politikayı hazırlarken
        aşağıda yer alan mevzuattan yararlandığımızı belirtmek isteriz:
      </p>

      <ol style={{ listStyle: "disc" }}>
        <li>
          GDPR (Genel Veri Koruma Yönetmeliği) olarak bilinen 2016/679 sayılı AB
          Tüzüğü
        </li>
        <li>6698 sayılı Kişisel Verilerin Korunması Yasası</li>
        <li>
          Kişisel Verileri Koruma Kurulu Çalışma Usul ve Esaslarına Dair
          Yönetmelik
        </li>
        <li>
          Kişisel Verilerin Silinmesi, Yok Edilmesi veya Anonim Hale Getirilmesi
          Hakkında Yönetmelik
        </li>
        <li>Veri Sorumluları Sicili Hakkında Yönetmelik</li>
        <li>Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ</li>
        <li>
          Aydınlatma Yükümlülüğünün Yerine Getirilmesinde Uyulacak Usul ve
          Esaslar Hakkında Tebliği
        </li>
        <li>Kurul Kararları</li>
      </ol>

      <p>
        <b>VERİ SORUMLUSU</b>, POLİTİKA'nın herkes tarafından erişilebilir,
        anlaşılır, okunabilir olmasını sağlar. (
        <a href="www.otellisanslama.org">www.otellisanslama.org</a> yerel
        ziyaret edenlerin, lisanslama sözleşmelerinin taraflarının, tüm
        sözleşmeci taraflarının)
      </p>
      <p>
        VERİ SORUMLUSU, profilinde tanımlı kurumsal değerlerle uyumlu ve
        gelecekte de akredite kriterlerini tutturabilecek bir sistem kurar.
      </p>
      <p>
        Bu Politikaya dayanarak, Yönetim bir sistemin geliştirilmesini stratejik
        bir seçenek olarak kabul eder.
      </p>

      <h3 className="h3">1. TANIMLAR</h3>
      <p>
        Bu Politikaya göre, koyu olarak vurgulanan aşağıdaki terimler aşağıdaki
        anlama gelir:
      </p>
      <p>
        www.otellisanslama.org MÜZFED ve MSF ile ortak biçimli ve daha fazla
        lisanslama ve maliyetlerinin aza indirilmesi amacı ile MÜZFED ve MSF'in
        faaliyet alanını ortak alanda birleştirmek üzere kurulmuştur.
      </p>
      <p>
        <b>AÇIK RIZA</b>, belli bir konuya ilişkin, bilgilendirmeye dayanan,
        özgür iradeyle açıklanan rızayı,
      </p>
      <p>
        <b>ANONİM HALE GETİRME</b>, kişisel verilerin, başka verilerle
        eşleştirerek dahi hiçbir surette kimliği belirli veya belirlenebilir bir
        gerçek kişiyle ilişkilendirilemeyecek hale getirilmesini,
      </p>
      <p>
        <b>İLGİLİ KİŞİ</b>, Kişisel verisi işlenen gerçek kişiyi,
      </p>
      <p>
        <b>KİŞİSEL VERİ</b>, Kimliği belirli veya belirlenebilir gerçek kişiye
        ilişkin her tür bilgi,
      </p>
      <p>
        <b>KİŞİSEL VERİLERİN İŞLENMESİ</b>, kişisel verilerin tamamen veya
        kısmen otomatik olan veya kısmen otomatik olan ya da herhangi bir veri
        kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde
        edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi,
        yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde
        edilir hale getirilmesi, sınıflandırılması ya da kullanılmasının
        engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü işlemi,
      </p>
      <p>
        <b>KURUL</b>, Kişisel Verilerin Korunması Kurulu’nu,
      </p>
      <p>
        <b>KURUM</b>, Kişisel Verilerin Korunması Kurumu’nu,
      </p>
      <p>
        <b>VERİ İŞLEYEN</b>, veri sorumlusunun verdiği yetkiye dayanarak onun
        adına kişisel verileri işleyen gerçek ve tüzel kişiyi,
      </p>
      <p>
        <b>VERİ KAYIT SİSTEMİ</b>, Kişisel verilerin belirli kriterlere göre
        yapılandırılarak işlendiği kayıt sistemini,
      </p>
      <p>
        <b>VERİ SORUMLUSU</b>, kişisel verilerin işleme amaçlarını ve
        vasıtalarını belirleyen, veri kayıt sisteminin kurulmasından ve
        yönetilmesinden sorumlu olan gerçek ve tüzel kişiyi,
      </p>
      <p>
        <b>ÇEREZLER</b>, ziyaret ettiğiniz web sitesi tarafından
        bilgisayarınızda depolanan ve kabul ettiğiniz kadarı ile profilleme ve
        kişiselleştirme yapan dosyaları
      </p>
      <p>
        <b>PROFİLLEME</b>, sitemizi ziyaret ettiğinizde kullanıcı kimliğiniz ile
        sıklıkla okuduğunuz haberler, ilgi alanlarınız ile ilgili seçenekleri
        kolayca bulmanızı sağlayan sistemin adını, ifade eder.
      </p>

      <h3 className="h3">2. KAPSAM</h3>
      <p>
        Politika, ortak lisanslama sözleşmesi taraflarını,
        www.otellisanslama.org sitesini ziyaret edenleri, lisanslama
        faaliyetinde çalışan sıfatıyla bulunanları, veri işleyenleri ve
        politikada tanımlı faaliyetleri gerçekleştirirken veri sorumlusunun
        hizmet satın aldığı hizmet sağlayıcıları, taşeronları, MÜZFED ve MSF
        bünyesinde çalışan herkesi kapsar.
      </p>

      <h3 className="h3">3. İŞLENEN VERİLERİN NİTELİĞİ</h3>
      <p>
        İşlenen veriler, 6698 sayılı yasa, GDPR’de tanımlanan kişisel veri ve
        kanunun zorunlu olarak toplanmasını ve işlenmesini şart olarak
        belirlediği hassas nitelikte kişisel verilerdir.
      </p>

      <h3 className="h3">4. VERİ KORUMA SORUMLUSU</h3>
      <p>
        Veri Sorumluları MÜZFED ve MSF olarak belirlenmiş olup, müteselsilen
        sorumluluk hükümleri geçerlidir, Veri Sorumlusu MÜZFED’e Kuloğlu mah.
        Turnacıbaşı Cad. No: 10 Kat:1 Beyoğlu/İstanbul Veri Sorumlusu MSF’e
        Esentepe Haberler Sok. No:4 Kat:2 34394 Şişli/İstanbul adresinden ve
        Veri Sorumlularına info@otellisanslama.org e-posta adresinden
        ulaşılabilir.
      </p>

      <h3 className="h3">5. KİŞİSEL VERİLERİ İŞLEME AMACI</h3>

      <p>Size sunduğumuz hizmetlere daha iyi katılımınızı sağlamak</p>

      <ol style={{ listStyle: "disc" }}>
        <li>
          Yasal düzenlemelerin gerektirdiği veya zorunlu kıldığı şekilde hukuki
          yükümlülüklerimizin yerine getirilmesinin sağlanması,{" "}
        </li>
        <li>
          MÜZFED ve MSF ile lisans sözleşmesi imzalayan kullanıcıların
          bilgilerinin temin edilmesi ve saklanması,
        </li>
        <li>
          Operasyonel faaliyetlerinin yerine getirilmesi için yazılım hizmetleri
          ve diğer iç / dış kaynak hizmetlerinin sağlanması,{" "}
        </li>
        <li>
          MÜZFED ve MSF'nin FSEK’te belirtilen faaliyetlerinin mevzuata ve
          ilgili geliştirilen politikalarımıza uygun olarak yerine getirilmesi
          için ilgili birimler tarafından gerekli çalışmaların yapılması ve bu
          doğrultuda faaliyetlerin yürütülmesi,
        </li>
        <li>
          MÜZFED ve MSF’nin kısa, orta, uzun vadeli çalışma politikalarının
          tespiti, planlanması ve uygulanması,{" "}
        </li>
        <li>
          MÜZFED ve MSF tarafından sunulan hizmet ve projelerin ilgili kişilerin
          beğeni, kullanım alışkanlıkları ve ihtiyaçlarına göre özelleştirilerek
          önerilmesi, hizmet ve projeler hakkında bilgi verilmesi,
        </li>
        <li>Etkin bir müşteri hizmetinin sunulabilmesi,</li>
        <li>Hizmet ve tekliflerin sunulması,</li>
        <li>Her türlü pazarlama ve reklam faaliyetlerinin yürütülebilmesi,</li>
        <li>Ziyaretçi profillerinin belirlenebilmesi,</li>
        <li>MÜZFED ve MSF’nin ticari güvenilirliğinin sağlanabilmesi</li>
        <li>
          İstek, talep ve şikayetlerin cevaplanarak çözümlenmesinin sağlanması,
        </li>
        <li>
          Sözleşme kapsamında ve hizmet standartları çerçevesinde Müşteri’lere
          ve Ziyaretçiler’e destek hizmetinin sağlanması
        </li>
        <li>Pazar araştırmaları ve istatistiksel çalışmalar yapılabilmesi,</li>
        <li>
          MÜZFED ve MSF ile iş ilişkisi içinde bulunan kişiler ile irtibat
          sağlanması,{" "}
        </li>
        <li>Pazarlama, uyum yönetimi, satıcı/tedarikçi yönetimi,</li>
        <li>Bilgi güvenliği süreçlerinin planlanması, denetimi ve icrası,</li>
        <li>Bilgi teknolojileri alt yapısının oluşturulması ve yönetilmesi,</li>
        <li>
          MÜZFED ve MSF çalışanlarının Veri Sahibi bilgilerine erişim
          yetkilerinin planlanması ve icrası,
        </li>
        <li>
          Faturalandırma da dahil, finans ve/veya muhasebe işlemlerinin takibi,
        </li>
        <li>Hukuk işlerinin takibi,</li>
        <li>Kurumsal iletişim faaliyetlerinin planlanması ve icrası,</li>
        <li>Verilerin doğru ve güncel olmasının sağlanması,</li>
      </ol>

      <p>
        Amaçları ile kişisel verileriniz işlenmektedir. Gereken Teknik ve İdari
        Tedbirler ile KVKK kapsamında düzenlenen yükümlülükler tarafımızca
        yerine getirilmektedir.
      </p>
      <p>
        Kişisel verilerinizin güvenli bir datada saklanması, gerektiğinde
        maskelenmesi, imha koşulları oluştuğunda imhası, sadece veri işleyenler
        tarafından işlenmesi, güvenli fiziki ya da dijital ortamlarda tutulması
        gibi tedbirler riskleri azaltmak üzere alınmıştır.
      </p>
      <p>
        Bu Politikanın uygulanması, veri korumayla ilgili riskleri en aza
        indirir.
      </p>

      <h3 className="h3">6. İŞLEMİN YASAL TEMELİ</h3>
      <p>
        Kişisel Veriler, MÜZFED ve MSF tarafından üretilen
        www.otellisanslama.org'nin kuruluş amaçlarını yürütme, sözleşmelerini
        yürütmek ve sürdürülebilirliğini sağlamak, MÜZFED ve MSF harcamaları
        www.otellisanslama.org sitesi üzerinden gerçekleştirilmesi için
        kullanılan yazılımlar ile kişisel yolcuları elden çıkarmak için gerekli
        idari Teknik ve teknik kısıtlamaları almak, internet sitesi üzerinden
        ödeme işlemlerinin gerçekleştirilmesini sağlamayı ve tanımlamayı
        hedefleyerek MÜZFED ve MSF operasyonları için istihdamı işe alma ve iş
        hedeflerini belirleme gibi amaçlarla işletilmektedir. Tüm iş ve işlemler
        6698 sayılı Kişisel Verilerin Korunması Kanunu'na uygun olarak
        işlenmekte ve imha kurallarına uygun olarak imha edilmektedir.
      </p>

      <h3 className="h3">7. KİŞİSEL VERİLERİN KORUNMASI YÖNTEMİ</h3>
      <p>
        Veri Sorumlusu, kişisel verilere yetkisiz erişimi, ifşa edilmesini,
        değiştirilmesini veya imha edilmesini önlemek için uygun güvenlik
        önlemlerini alır.
      </p>
      <p>
        Kişisel Verilerin Korunması, BT ve / veya telematik araçları
        kullanılarak, organizasyonel yöntemlerle ve belirtilen amaçlarla korunur
        ve koruma önlemleri üst seviyede alınır.
      </p>
      <p>
        Verilerin analizi ve işlenmesi, bu tür verileri kendi başına
        kullanamayacak olan üçüncü taraf şirketlerin yardımıyla da
        gerçekleştirilebilir. Postalama hizmeti (elektronik posta, bulut
        kullanımı) adreslerin iletilebileceği üçüncü taraf şirketlerin teknik
        yardımı ile de yalnızca bu amaçlar için gerçekleştirilebilir.
      </p>
      <p>
        Veri sorumlusunun anlaşmalı olduğu bulut hizmeti de kişisel verilerin
        korunması yöntemlerinden biridir.
      </p>

      <h3 className="h3">8. İŞLENEN KİŞİSEL VERİ TÜRLERİ</h3>
      <ol style={{ listStyleType: "lower-alpha" }}>
        <li>
          Navigasyon verileri
          <p>
            Normal işletimleri sırasında, bu siteyi işletmek için kullanılan BT
            sistemleri ve yazılım prosedürleri, iletimi İnternet iletişim
            protokollerinin kullanımında örtük olan bazı kişisel veriler elde
            eder. Bu, belirlenmiş ilgili taraflarla ilişkilendirilmek üzere
            toplanmayan ancak doğası gereği üçüncü taraflarca tutulan veriler
            işlenerek ve ilişkilendirilerek kullanıcıların tanımlanmasına izin
            verebilecek bilgilerdir. Bu veri kategorisi, siteye bağlanan
            kullanıcılar tarafından kullanılan bilgisayarların ve terminallerin
            IP adreslerini veya etki alanı adlarını, istenen kaynakların URI /
            URL (Tekdüzen Kaynak Tanımlayıcı / Konumlandırıcı) adreslerini,
            isteğin zamanını içerir. isteği sunucuya göndermek için kullanılan
            yöntem, yanıtta elde edilen dosyanın boyutu, sunucu tarafından
            verilen yanıtın durumunu gösteren sayısal kod (başarılı, hata vb.)
            ve işletim sistemi ve ortamla ilgili diğer parametreler kullanıcı
            bilgisayarı gibi veriler burada yer alır. Web servislerinin
            kullanımı için gerekli olan bu veriler sadece aşağıdaki amaçlarla
            kullanılır:
          </p>
          <p>
            Hizmetlerin kullanımı hakkında anonim istatistiksel bilgiler edinir.
            (en çok ziyaret edilen sayfalar, zamana veya güne göre ziyaretçi
            sayısı, coğrafi menşe alanları vb.); Sunulan hizmetlerin doğru
            çalışıp çalışmadığını (anormallikler ve / veya suistimaller) kontrol
            eder.
          </p>
          <p>Navigasyon verileri 1 yıldan fazla kalmaz</p>
        </li>
        <li>Reklam, Ad, soyad, E-posta ve adres verileri, IP adresleri,</li>
        <li>
          Şirket ve gerçek kişilere ait şirket adresleri, şirket ünvanı, gerçek
          kişilere ait işletme ad ve verileri, vergi dairesi ve numarası,
        </li>
        <li>
          Fonogram yapımcısı ve icracı sanatçılar tarafından alenileştirilen
          veriler,
        </li>
        <li>
          www.otellisanslama.org uzantılı internet sitesi adına yapılan tüm
          sözleşmeler nedeni ile gerçek kişi adı, soyadı, temsilcisinin adı,
          soyadı, temsilcisinin TC kimlik numarası ya da pasaport numarası,
          temsilcisinin adres bilgileri, vekaletname verileri, kimlik
          fotokopisinde yer alan tüm bilgileri,
        </li>
        <li>
          www.otellisanslama.org’nin gereklilikleri gerekli harçlar ve ödemeler
          için, www.otellisanslama.org üzerinden ödeme aplikasyonu için gerekli
          olan kredi kart numarası, güvenlik kodu ve kredi kartının ön kısmında
          yer alan kredi kartının son kullanım tarihi,
        </li>
        <li>Çerezler ve diğer izleme sistemleri.</li>
        <li>
          www.otellisanslama.org uzantılı internet sitesinin çalışanlarının iş
          sözleşmelerinin geçerliliği ve iş sözleşmelerinden elde edilecek hak
          ve alacaklarının belirlenmesi için toplanan tüm bölgeleri (Ad, Soyadı,
          TC Kimlik Numarası ya da yerine geçen diğer bir kimlik numarası, adli
          sicil kaydı, sağlık raporu, iş sağlığı ve güvenliğinin elde edilmesi
          gereken iş yeri hekimi) muayenesi (gerekli görünümünde), Banka hesap
          numarası verileri, çocuk sayısı, medeni hal verileri, fotoğraf, vergi
          dairesinden giderleri hakkında bilgi belgesi gibi) Çerezler,
          kullanıcının ziyaret ettiği sitelerin bilgisayarına veya mobil cihaza
          ait ve kaydettiği küçük metin dosyalarıdır ve daha sonra bir sonraki
          ziyarette aynı site tekrarlara gönderilir. Çerezler sayesinde, bir
          site kullanıcılarının eylemlerini ve tercihlerini ( örneğin, giriş
          verileri, seçilen dil, yazı tipi kısıtlamaları, diğer ekran ayarları
          vb.) izler. Bu nedenle çerezler, bir siteye erişen kullanıcı grupları
          ile ilgili bilgisayar kimlik doğrulaması, oturum izleme ve depolama
          arşivi yönetimi için kullanılır ve ayrıca kullanıcıların içinde
          gezinmeyi takip etmelerini sağlayan benzersiz bir kimlik kodu arar.
          Çerezlerin kullanımı için kullananların açık izni her zaman gerekli
          değildir. Özellikle "teknik çerezler" bu rızayı, yani yalnızca bir
          iletişimin bir elektronik iletişim ağı üzerinden iletilmesini sağlamak
          için veya kullanıcı tarafından talep edilen bir hizmeti sağlamak için
          gerekli olduğu ölçüde kullanılanlar için gerekli değildir. Başka bir
          deyişle, bunların sitenin çalışması için gerekli olan veya kullanıcı
          tarafından istenen etkinlikleri gerçekleştirmesi gereken çerezlerdir.
          Kullanımları için açık bir onay gerektirmeyen teknik çerezler
          arasında, kişisel verilerin korunması için;
        </li>
      </ol>

      <ol style={{ listStyleType: "lower-roman" }}>
        <li>
          Kullanıcı sayısı ve siteyi nasıl ziyaret ettikleri hakkında toplu
          formda bilgi toplamak için doğrudan site operatörü tarafından
          kullanıldığında "analiz çerezleri",
        </li>
        <li>Gezinme veya oturum çerezleri (kimlik doğrulaması için),</li>
        <li>
          Kullanıcıya sunulan hizmeti geliştirmek için seçilen bir dizi kritere
          (örneğin, dil, satın almak için seçilen ürünler) göre gezinmesini
          sağlayan işlev çerezleri.
        </li>
        <li>
          Öte yandan "profilleme çerezleri" için, yani kullanıcıyla ilgili
          profiller oluşturmayı amaçlayan ve internette sörf bağlamında aynı
          şekilde ifade edilen tercihler doğrultusunda reklam mesajları
          göndermek için kullanılanlar.
        </li>
      </ol>

      <h3 className="h3">KİŞİSEL VERİLERİN AKTARMA ŞARTLARI</h3>
      <p>
        Kişisel veriler, ilgili kişinin açık rızası olmaksızın aktarılmaz. Genel
        kural bu olmakla birlikte şu hallerde ilgilinin açık rızası alınmadan
        aktarma yapılabilir:
      </p>

      <ol style={{ listStyleType: "lower-roman" }}>
        <li>Kanunlarda açıkça öngörülmesi,</li>
        <li>
          Fiili imkânsızlık nedeni ile rızasını açıklayamayacak durumda olan
          veya rızasına hukuki geçerlilik tanınmayan kişinin kendisinin ya da
          bir başkasının hayatı veya beden bütünlüğünün korunması için zorunlu
          olması,
        </li>
        <li>
          Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması
          kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin
          gerekli olması,
        </li>
        <li>
          Veri sorumlusunun hukuki yükümlülüğünü yerine getirilebilmesi için
          zorunlu olması,
        </li>
        <li>İlgili kişinin kendisi tarafından alenileştirilmiş olması,</li>
        <li>
          Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin
          zorunlu olması,
        </li>
        <li>
          İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla,
          veri sorumlusunun meşru menfaatleri için veri işlemenin zorunlu
          olması.
        </li>
      </ol>

      <h3 className="h3">ÖZEL NİTELİKLİ VERİLERİN AKTARMA ŞARTLARI</h3>
      <p>
        Özel nitelikli kişisel veriler de ilgilinin açık rızası olmaksızın
        aktarılamaz ancak aşağıdaki hallerde açık rıza şartı aranmaz:
      </p>
      <ol style={{ listStyleType: "lower-roman" }}>
        <li>
          Sağlık ve cinsel hayat dışındaki kişisel veriler, kanunlarda öngörülen
          hallerde ilgili kişinin açık rızası aranmaksızın işlenebilir.
        </li>
        <li>
          Sır saklama yükümlülüğü altında bulunan kişiler veya yetkili kurum ve
          kuruluşlar tarafından ilgilinin açık rızası aranmaksızın işlenebilir.
        </li>
      </ol>
      <p>
        Kurul tarafından belirlenen yeterli önlemler de işleme ve aktarma
        şartlarında esas alınmaktadır.
      </p>

      <h3 className="h3">11. AKTARILAN KİŞİSEL VE ÖZEL NİTELİKLİ VERİLER</h3>
      <p>
        Verileriniz kişisel verilerin aktarımını zorunlu kılan bazı hallerde
        aktarılmaktadır. Örneğin, Mahkeme, Savcılık soruşturma talepleri, Yasal
        takiplerin yerine getirilmesi, Vergi dairesine yapılması gereken
        bildirimler, muhasebe işlemlerinin gerçekleştirilmesi, Kültür ve Turizm
        Bakanlığı’na sunulması gereken bilgiler nedeni ile aktarılmaktadır. Bu
        örneklere benzer örnekler nedeni ile de kişisel verileriniz
        aktarılmaktadır. OLB tarafından aktarma durumları sadece yasal
        gereklilikler nedeni ile yapılmaktadır.
      </p>

      <p>
        BT güvenliği, e-postalarınızın yasal süresi boyunca saklanması,
        evraklarınızın güvenliği nedeni ile de server ağları yurtiçinde bulunan
        şirketlerin teknik yardımı ile KVKK yükümlülükleri de yerine getirilmek
        sureti ile verileriniz aktarılmaktadır.
      </p>

      <h4 className="h4">
        Ve aşağıda yer alan diğer amaçlarla verileriniz aktarılmaktadır:
      </h4>
      <p>
        Kişisel veriler; “Kanun” tarafından verilen temel ilkelere uygun olarak
        ve “Kanun”un 8. ve 9. tüketicilerinde belirtilen kişisel veri işleme
        hükümleri ve amaçlarına uygun olarak, MÜZFED'ın ve MSF'nin meşru ve
        hukuka uygun kişisel veri işleme amaçlarına ulaşmasını sağlar , MÜZFED
        ve MSF tarafından aşağıda yer alan amaçlarla;
      </p>

      <ol style={{ listStyle: "disc" }}>
        <li>Ticari itibarının ve oluşturduğu güvenin korunması, </li>
        <li>
          Kısa, orta ve uzun vadede ticari ve iş stratejilerini belirlenmesi,
          planlanması ve uygulanması{" "}
        </li>
        <li>
          Sunulan hizmet ve projelerden sizleri faydalandırmak için gerekli
          çalışmaların iş birimlerimiz tarafından yapılması,{" "}
        </li>
        <li>
          MÜZFED'ın ve MSF'nin FSEK'te belirtilen faaliyetler kapsamında gerekli
          iş ve gerçekleştirmenin sağlanması ve bu doğrultuda hedeflenen,
        </li>
        <li>
          İş ilişkisi içerisinde olunan kişilerin, kuruluşların, resmi
          mercilerin ve diğer meslek birliklerinin hukuki ve ticari güvenliğinin
          temini,
        </li>
        <li>
          MÜZFED ve MSF tarafından yürütülen yönetilen idari operasyonlar, hava
          lokasyonlarının klinik bölümleri ve denetimini sağlamak, iş
          ortağı/müşteri/diğer meslek birlikleri/tedarikçi (yetkili veya
          çalışanları) denetleme operasyonları, itibari araştırma operasyonları,
          operasyonlar uyum süreci, denetim, mali işler vb.),
        </li>
        <li>
          İnsan kaynakları hizmetlerinin temini Amaçlarıyla MÜZFED ve
          MSFgörevlilerine, çalışanlara, hizmet vericilerimize, diğer meslek
          birimlerine, resmi mercilere, iş ortaklarımıza, birimlerimize
          “Kanun”en yetkili kurum ve kuruluşlara aktarılır.
        </li>
      </ol>
      <p>Kişisel verileriniz yurtdışına aktarılmamaktadır. </p>

      <h3 className="h3">
        12. DİĞER VERİ AKTARIM NEDENLERİ VE YURTDIŞI VERİ AKTARIMI
      </h3>
      <p>
        GDPR düzenlemesi uyarınca Avrupa Birliğine üye ülke vatandaşlarına
        verilen hizmetler doğrultusunda yurtdışına veri aktarımı belli koşullar
        altında yapılmaktadır. Yurtdışına veri aktarımı sırasında aktarılacak
        ülkenin Kişisel Verilerin Korunması Kurumu’nun kabul ettiği yeterli
        koruma altında ülkelerden olması ve yeterli önlemlerin alınması
        gerekmektedir.
      </p>
      <p>
        Bazı kişisel verileriniz Avrupa Ekonomik Alanı dışında bulunabilecek
        alıcılarla paylaşılmaktadır. Bu verilerin, GDPR, KVKK’ya uygun olarak
        aktarılmasını sağlar.
      </p>
      <p>
        Veriler, 3. bir ülkeye aktarılmamaktadır. Aktarılması durumunda Kişisel
        Verilerin Korunması Kanunu’nun 9. maddesinde yer alan hükümler
        uygulanacaktır. Buna göre;
      </p>

      <p>- İlgili kişinin açık rızası olmaksızın yurtdışına aktarılamaz.</p>
      <p>
        - Kanunlarda açıkça öngörülmesi, fiili imkânsızlık nedeniyle rızasını
        açıklayamayacak durumda bulunan veya rızasına hukuki geçerlilik
        tanınmayan kişinin kendisinin ya da bir başkasının hayatı veya beden
        bütünlüğünün korunması için zorunlu olması, bir sözleşmenin kurulması
        veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin
        taraflarına ait kişisel verilerin işlenmesinin gerekli olması, veri
        sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu
        olması, ilgili kişinin kendisi tarafından alenileştirilmiş olması, bir
        hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu
        olması, ilgili kişinin temel hak ve özgürlüklerine zarar vermemek
        kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin
        zorunlu olması hallerinde ise;
      </p>

      <ol style={{ listStyleType: "lower-roman" }}>
        <li>
          Yeterli korumanın bulunması (Aktarma ülkesinde veri koruma kanunun
          bulunması, yeterli yasal güvencelerin ve şikayet mekanizmalarının
          kurulmuş olması, aktarılacak veri sorumlusunun yeterli önlemleri almış
          olması gibi)
        </li>
        <li>
          Yeterli korumanın bulunmaması durumunda Türkiye’deki ve ilgili yabancı
          ülkedeki veri sorumlularının yeterli bir korumayı yazılı olarak
          taahhüt etmeleri ve Kurul’un yazılı izninin bulunması, durumlarında
          açık rıza şartı aranmaksızın kişisel veriler aktarılır.{" "}
        </li>
      </ol>

      <h3 className="h3">13. VERİ TUTMA SÜRESİ</h3>
      <p>
        Kişisel veriler, GDPR ve KVKK uyarınca, veri minimizasyonu ilkesi ve
        işlemenin sınırlandırılması ilkelerine uygun olarak toplanma amaçlarının
        gerektirdiği süre boyunca işlenir ve saklanır.{" "}
      </p>
      <p>
        Veri Sorumlusunun meşru menfaati ile ilgili amaçlar için toplanan
        Kişisel Veriler, bu menfaat karşılanıncaya kadar saklanacaktır. İlgili
        kişi, Veri Sorumlusu tarafından takip edilen meşru menfaat ile ilgili
        daha fazla bilgi alabilir veya Veri Sorumlusu ile iletişim kurabilir.
      </p>
      <p>
        İlgili kişinin açık rızasının alınması gereken hallerde, Veri Sorumlusu
        bu rıza iptal edilene kadar kişisel verileri daha uzun süre tutabilir.
        Ayrıca, Veri Sorumlusu, kişisel verileri yasal bir zorunluluğa veya bir
        makamın emrine göre daha uzun süre tutmak zorunda kalabilir.
      </p>
      <p>
        Saklama süresinin sonunda kişisel veriler silinecektir. Bu nedenle, bu
        sürenin sonunda erişim, iptal, düzeltme ve veri taşınabilirliği hakkı
        artık kullanılamaz.
      </p>
      <p>
        Veri sorumlusu, kişisel verileri çocuklarla ve kısıtlılarla ilgili
        kasıtlı olarak toplamaz. Reşit olmayanlarla ilgili ya da kısıtlılarla
        ilgili kişisel verilerin verilerinin yasal temsilcisinin izni ile
        toplanması ya da bazı durumlarda resen işleme halleri geçerli olacaktır.
        Açık rıza koşulu olan durumlarda veri sorumlusu rıza olmadığının tespiti
        halinde kişisel verileri zamanında silecektir.
      </p>

      <h3 className="h3">14. İLGİLİ KİŞİNİN HAKLARI</h3>
      <p>
        Kişisel verilerin işlenmesi durumunda, ilgili taraflar bu konuda bilgi
        edinme fırsatına sahip olmalıdır.
      </p>
      <p>KVKK m. 11 ilgili kişinin haklarını saymıştır. Buna göre;</p>
      <p>11.1. Kişisel veri işlenip işlenmediğini öğrenme,</p>
      <p>11.2.Kişisel verileri işlenmiş ise buna ilişkin veri talep etme,</p>
      <p>
        11.3.Kişisel verilerin işlenme amacını ve bu amaca uygun olarak
        kullanılıp kullanılmadığını öğrenme,
      </p>
      <p>
        11.4.Yurtiçinde ve yurtdışında kişisel verilerinin aktarıldığı 3.
        Kişileri bilme,
      </p>
      <p>
        11.5.Kişisel verilerin eksik ve hatalı olarak işlenmesi halinde
        düzeltilmesini talep etme,
      </p>
      <p>
        11.6.Kişisel verilerin işleme şartları sona erdiğinde silinmesini ve yok
        edilmesini isteme,
      </p>
      <p>
        11.7. Kişisel verilerinin hukuka aykırı olarak işlenmesi dolayısı ile
        zarar gördüğü hallerde zararın giderilmesini isteme,{" "}
      </p>
      <p>
        11.8..Kişisel verilerin işlenmesi sırasında otomatik olarak karar alma
        mekanizmalarına dahil olmama hakkı ve unutulma hakkı talep etme,
      </p>

      <p>hakları vardır.</p>

      <h3 className="h3">15. KİŞİSEL VERİLERİN İŞLENMESİNDE GEÇERLİ İLKELER</h3>
      <p>
        Kişisel veriler yasal olarak ve gizlilik haklarını koruyacak şekilde
        işlenmelidir.
      </p>
      <p>Bu amaçla, aşağıdaki veri koruma ilkelerine uyulmalıdır.</p>

      <p>15.1 Hukuka ve dürüstlük kurallarına uygun olarak işleme</p>

      <p>
        Bu temel ilke, veri işleme süreçlerinde belirliliğin sağlanmasını
        beraberinde getirir.
      </p>
      <p>15.2 Oransallık</p>
      <p>
        Kişisel verilerin işlenmesinde orantılılık ilkesi gözetilmelidir.
        Kişisel verileriniz işlenirken amaçla orantılı şekilde işlenmelidir.
      </p>
      <p>15.3 Şeffaflık</p>
      <p>
        Prensip olarak, ilgili kişinin verilerine talep ettiği anda
        ulaşabilmeli, veri sorumlusu tarafından da yeterli şekilde
        bilgilendirilmelidir.
      </p>
      <p>15.4 Veri ekonomisi ve "bilmenin gerekliliği"</p>
      <p>
        Kişisel veriler, belirlenen amaçlara ulaşmak için gereken ölçüde
        işlenmelidir. Verileri işleme hedefine de makul bir veri talebi ile
        ulaşılmalıdır. Prensip olarak, kişisel veriler önceden toplanamaz ve
        potansiyel yöntemler ya da durumlar için saklanamaz.{" "}
      </p>
      <p>
        Kanuni düzenlemelerin, sözleşme ilişkilerinin yönetilmesi ile kamu kurum
        ve kuruluşlarının talebi ile kişisel verileriniz amaçla orantılı olarak
        işlenir. İmha koşulları oluştuğunda verileriniz silinecektir.
      </p>
      <p>Kişisel bilgilere erişim, "bilme gereği ilkesi" temelinde sağlanır.</p>
      <p>
        Bu ilke, insanların kesintisiz ve kişisel bilgilere bağlı olarak kişisel
        verilere erişebilecekleri anlamına gelir.
      </p>
      <p>15.5 Veri Kalitesi</p>
      <p>
        Bu ilke, insanların kesintisiz ve kişisel bilgilere bağlı olarak kişisel
        verilere erişebilecekleri anlamına gelir.
      </p>
      <p>
        Yanlış veya eksik verilerin doğru, güncel ve entegre olduğundan emin
        olmak için yeterli önlemler alınmalıdır.
      </p>
      <p>15.6 İşlemin gizliliği</p>

      <p>
        Kişisel veriler yetkisiz kişilerin erişiminden korunmalıdır. Kişisel
        verilerin yetkisiz kişiler tarafından işlenmesi yasaktır. Yetkili olarak
        görevlendirilen kişilerin, veri işleme faaliyetini ya da erişimini
        yetkilendirilmemiş kişilere devri de yasaktır ve bu konuda politikada ve
        gizlilik sözleşmelerinde düzenlenen hassasiyet gösterilir.
      </p>

      <h3 className="h3">16. VERİ SORUMLUSUNA BAŞVURU</h3>

      <p>
        İlgili kişi KVKK hükümlerinin uygulanması için taleplerini önce veri
        sorumlusuna iletecektir. Veri Sorumlusuna başvuru www.lisanslama.org
        adresinde mevcut olan başvuru formu tam ve eksiksiz doldurularak
        yapılacaktır. İlgili kişi bu başvuruyu yazılı olarak ıslak imzalı bir
        biçimde yapabileceği gibi, kayıtlı elektronik posta adresi, e-imza,
        m-imza, veri sorumlusuna önceden bildirilmiş ve onda kayıtlı bulunan
        e-posta adresi ya da başvuruya yönelik geliştirilmiş yazılım uygulama
        aracılığı ile gerçekleştirilebilir.
      </p>
      <p>
        İlgili kişi, Veri sorumlusu’nun başvuruya cevabını öğrendiği tarihten
        itibaren 30 gün ve herhalde 60 gün içinde Kurul’a şikâyet başvurusunda
        bulunabilir. Başvuru formu için tıklayınız.
      </p>

      <h3 className="h3">17. VERİ SORUMLUSUNUN ROLÜ VE SORUMLULUKLARI</h3>
      <p>
        Veri sorumlusu kişisel verilerin hukuka aykırı işlenmesini önlemek,
        hukuka aykırı erişilmesini önlemek ve muhafazasını sağlamak zorundadır.{" "}
      </p>
      <p>
        Verilerin başka bir gerçek / tüzel kişi tarafından işlenmesi durumunda
        müştereken ve müteselsilen sorumluluk söz konusudur.
      </p>
      <p>
        MÜZFED ve MSF kişisel verilerin korumaları için gerekli tüm idari ve
        teknik korumaları alır ve gereken denetimleri zamanında yaptırır.
      </p>
      <p>
        Kişisel verilerin korunması konusunda tüm idari ve teknik tedbirler
        alınmasına rağmen verilerin başkaları tarafından elde edilmesi halinde
        veri sorumlusu durumu en kısa sürede (72 saat) Kişisel Verilerin
        Korunması Kurulu’na bildirir.
      </p>

      <h3 className="h3">18. YETKİ MATRİSİ</h3>
      <div style={{ border: "1px solid #000", padding: 25, width: "100%" }}>
        <h6>
          KİŞİSEL VERİ SORUMLUSU www.otellisanslama.org ADINA (MÜZFED VE MSF
          ORTAK VERİ SORUMLUSU)
        </h6>
        <p>Birlik içinde KVKK uygulanmasını sağlamak</p>
      </div>
      <div
        className="mt-3"
        style={{ border: "1px solid #000", padding: 25, width: "100%" }}
      >
        <h6>KİŞİSEL VERİ İŞLEYENLER</h6>
        <p>
          -Kişisel verileri üstlerinin talimatları ve usul ve yasaya uygun
          işlemek
        </p>
        <p>-Kişisel verileri yetkisiz kişilerle paylaşmamak,</p>
        <p>-Üstlerinin uygun gördüğü eğitimlere katılmak,</p>
        <p>
          -Sistem ve işleyişteki aksaklıkları vakit geçirmeksizin üstlerine
          bildirmek.
        </p>
      </div>

      <h3 className="h3">19. BİLGİ GÜVENLİĞİ ORGANİZASYONU</h3>
      <p>
        Özellikle teknik ve kurumsal sınırlamaya sahip bulunarak, KVKK,
        yönetenler ve uluslararası sınırlar baz varsayımı ilkeleri politikamız
        her daim kişisel verilerinizin en yüksek düzeyde korunması için çaba
        sarf etmektir. Bu nedenle MÜZFED ve MSF bünyesinde kişisel koruyucun
        koruma için bilgi güvenliği düzenlemesi yapılmıştır.
      </p>

      <h3 className="h3">20. BİLDİRİM YÜKÜMLÜLÜĞÜ</h3>
      <p>
        Kişisel verilerin burada tanımlanan ilkelere aykırı olarak kullanılması
        durumunda, MÜZFED ve MSF gerekli idari ve teknik belgeleri alır ve
        Kişisel Verilerin Korunması Kurulu'na ve ilgili kişinin kayıtlı e-posta
        gönderme aykırılık olması en kısa zamanda beyan edilir. Bu durum bir
        rapora gereksinimleri ve gerekli idari / teknik önlemlerin alındığı
        hususu da raporda belirtilir ve hangi önlemlerin alındığı listelenir.
      </p>

      <h3 className="h3">21. POLİTİKA DEĞİŞİKLİKLERİ</h3>
      <p>
        Politika düzenlemesi değişebilir. Bu politikayı ilgili kişi düzenli
        olarak internet sayfamızdan kontrol etmeli ve güncel olan esas
        alınmalıdır.
      </p>
    </>
  );
};

export default ProtectionOfPersonalDataText;
