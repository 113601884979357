import PageHeader from "@otel-lisanslama/components/PageHeader";
import { Col, Container, Row } from "reactstrap";

const Contact = () => {
  return (
    <>
      <PageHeader title="İletişim" />
      <Container className="my-5">
        <Row>
          <Col lg={6}>
            <div className="mt-4 pt-3">
              <div className="d-flex text-muted align-items-center mt-2">
                <div className="flex-shrink-0 fs-22 text-primary">
                  <i className="uil uil-map-marker"></i>
                </div>
                <div className="flex-grow-1 ms-2">
                  <p className="mb-0">
                    Esentepe Mahallesi Haberler Sokak No:4
                    <br />
                    ŞİŞLİ / İSTANBUL
                  </p>
                </div>
              </div>
              <div className="d-flex text-muted align-items-center mt-2">
                <div className="flex-shrink-0 fs-22 text-primary">
                  <i className="uil uil-envelope"></i>
                </div>
                <div className="flex-grow-1 ms-2">
                  <p className="mb-0">info@otellisanslama.org</p>
                </div>
              </div>
              <div className="d-flex text-muted align-items-center mt-2">
                <div className="flex-shrink-0 fs-22 text-primary">
                  <i className="uil uil-phone-alt"></i>
                </div>
                <div className="flex-grow-1 ms-2">
                  <p className="mb-0">(+90) 212 963 0 813</p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6} className="align-items-center">
            <div className="map">
              <iframe
                title="maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3007.9784310203813!2d29.006030775604707!3d41.06946411565572!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab65078b1a6a5%3A0xa66294e657757fe9!2zRXNlbnRlcGUsIEhhYmVybGVyIFNrLiBObzo0LCAzNDM5NCDFnmnFn2xpL8Swc3RhbmJ1bA!5e0!3m2!1sen!2str!4v1683543582140!5m2!1sen!2str"
                height="450"
                style={{ border: 0 }}
                allowFullScreen={false}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="w-100"
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Contact;
