import axios from "@otel-lisanslama/libs/axios";
import { CsrfContextProps, CsrfProviderProps } from "@otel-lisanslama/types";
import {
  addCsrfTokenInterceptor,
  fetchCsrfToken,
} from "@otel-lisanslama/utils/csrf-token";
import React, { useContext, useEffect, useState } from "react";

const CsrfContext = React.createContext<CsrfContextProps>({
  csrfToken: null,
});

export const CsrfProvider: React.FC<CsrfProviderProps> = ({ children }) => {
  const [csrfToken, setCsrfToken] = useState<string | null>(null);

  useEffect(() => {
    if (csrfToken == null) {
      fetchCsrfToken().then((token) => {
        setCsrfToken(token);
        addCsrfTokenInterceptor(token, axios);
      });
    }
  }, [csrfToken]);

  useEffect(() => {
    addCsrfTokenInterceptor(csrfToken || "", axios);
  }, [csrfToken]);

  return (
    <CsrfContext.Provider value={{ csrfToken }}>
      {children}
    </CsrfContext.Provider>
  );
};

export const useCsrfToken = () => {
  const { csrfToken } = useContext(CsrfContext);
  return csrfToken;
};
