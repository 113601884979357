import AppLayout from "@otel-lisanslama/layouts/AppLayout";
import About from "@otel-lisanslama/pages/About";
import Contact from "@otel-lisanslama/pages/Contact";
import FAQ from "@otel-lisanslama/pages/FAQ";
import MusicLicense from "@otel-lisanslama/pages/MusicLicense/MusicLicense";
import PrivacyStatement from "@otel-lisanslama/pages/Privacy/PrivacyStatement";
import ProtectionOfPersonalData from "@otel-lisanslama/pages/Privacy/ProtectionOfPersonalData";
import Tariffs from "@otel-lisanslama/pages/Tariffs";
import React from "react";
import { Route, Routes } from "react-router-dom";

const CompaniesPage = React.lazy(
  () => import("@otel-lisanslama/pages/Companies")
);

const MainRoutes = () => {
  return (
    <AppLayout subPage={true}>
      <Routes>
        <Route
          path="/kisisel-verilerin-korunmasi-ve-islenmesi-politikasi"
          element={<ProtectionOfPersonalData />}
        />
        <Route path="/gizlilik-beyani" element={<PrivacyStatement />} />
        <Route path="/hakkimizda" element={<About />} />
        <Route path="/iletisim" element={<Contact />} />
        <Route path="/sikca-sorulan-sorular" element={<FAQ />} />
        <Route path="/lisans-alan-firmalar" element={<CompaniesPage />} />
        <Route path="/muzik-lisans-belgesi" element={<MusicLicense />} />
        <Route path="/tarifeler" element={<Tariffs />} />
      </Routes>
    </AppLayout>
  );
};

export default MainRoutes;
