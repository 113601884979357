import AuthLayout from "@otel-lisanslama/layouts/AuthLayout/AuthLayout";
import React from "react";
import { Route, Routes } from "react-router-dom";

const SignInPage = React.lazy(
  () => import("@otel-lisanslama/pages/Auth/SignIn")
);
const ForgotPasswordPage = React.lazy(
  () => import("@otel-lisanslama/pages/Auth/ForgotPassword")
);
const ResetPasswordPage = React.lazy(
  () => import("@otel-lisanslama/pages/Auth/ResetPassword")
);

const AuthRoutes = () => (
  <AuthLayout>
    <Routes>
      <Route path="/signin" element={<SignInPage />} />
      <Route path="/forgot-password" element={<ForgotPasswordPage />} />
      <Route path="/reset-password" element={<ResetPasswordPage />} />
    </Routes>
  </AuthLayout>
);

export default AuthRoutes;
