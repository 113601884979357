import DashboardLayout from "@otel-lisanslama/layouts/DashboardLayout/DashboardLayout";
import React from "react";
import { Route, Routes } from "react-router-dom";

const ApplicationListPage = React.lazy(
  () => import("@otel-lisanslama/pages/Dashboard/Applications/ApplicationList")
);
const ApplicationNewPage = React.lazy(
  () => import("@otel-lisanslama/pages/Dashboard/Applications/ApplicationNew")
);

const DashboardRoutes = () => (
  <DashboardLayout>
    <Routes>
      <Route path="/basvurular">
        <Route path="liste" element={<ApplicationListPage />} />
        <Route path="yeni" element={<ApplicationNewPage />} />
      </Route>
    </Routes>
  </DashboardLayout>
);

export default DashboardRoutes;
