export const GA_TRACKING_ID = 'G-MQHY60R5QV';

export const pageview = (path: string) => {
  window.gtag('config', GA_TRACKING_ID, {
    page_path: path,
  });
};

export const event = (
  action: string,
  category: string,
  label?: string,
  value?: number
) => {
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};
