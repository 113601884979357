import { Row, Col, Table } from "reactstrap";

function ApplicationFormText() {
  return (
    <>
      <h3 className="h3 h3-responsive text-center mb-5">BAŞVURU FORMU</h3>

      <Row>
        <Col md="5" sm="12">
          Ad Soyad
        </Col>
        <Col md="7" sm="12">
          :{" "}
        </Col>
      </Row>

      <Row>
        <Col md="5" sm="12">
          T.C kimlik No / Pasaport Numarası/Mavi Kart No
        </Col>
        <Col md="7" sm="12">
          :{" "}
        </Col>
      </Row>

      <Row>
        <Col md="5" sm="12">
          Adres
        </Col>
        <Col md="7" sm="12">
          :{" "}
        </Col>
      </Row>

      <Row>
        <Col md="5" sm="12">
          KEP (E-POSTA)
        </Col>
        <Col md="7" sm="12">
          :{" "}
        </Col>
      </Row>

      <Row>
        <Col md="5" sm="12">
          TALEP KONUSU
        </Col>
        <Col md="7" sm="12">
          :{" "}
        </Col>
      </Row>

      <Row>
        <Col md="5" sm="12">
          EKTE YER ALAN BELGELER
        </Col>
        <Col md="7" sm="12">
          :{" "}
        </Col>
      </Row>

      <h3 className="h3 h3-responsive mt-5">
        BAŞVURUDA TALEP KONUSU HAKLARI AŞAĞIDAKİ TABLODAN İŞARETLEYİNİZ
      </h3>

      <Table responsive bordered>
        <tbody>
          <tr>
            <td style={{ width: "60%" }}>Kişisel Verilerim İşleniyor mu?</td>
            <td style={{ width: "30%" }}>KVKK m. 11/a</td>
            <td style={{ width: "10%" }}> </td>
          </tr>
          <tr>
            <td style={{ width: "60%" }}>
              Kişisel Verilerim İşleniyor ise bilgi verilmesini istiyorum.
            </td>
            <td style={{ width: "30%" }}>KVKK m. 11 /b</td>
            <td style={{ width: "10%" }}> </td>
          </tr>
          <tr>
            <td style={{ width: "60%" }}>
              Kişisel verilerim ne amaçla işleniyor ve işleme amacı
              doğrultusunda mı kullanılıyor?
            </td>
            <td style={{ width: "30%" }}>KVKK m. 11/c</td>
            <td style={{ width: "10%" }}> </td>
          </tr>
          <tr>
            <td style={{ width: "60%" }}>
              Kişisel verilerim yurtiçinde ve yurtdışında kimlerle paylaşılıyor?
            </td>
            <td style={{ width: "30%" }}>KVKK m. 11/ç</td>
            <td style={{ width: "10%" }}> </td>
          </tr>
          <tr>
            <td style={{ width: "60%" }}>
              Kişisel Verilerimin güncellenmesini talep ediyorum.
            </td>
            <td style={{ width: "30%" }}>KVKK m. 11/d</td>
            <td style={{ width: "10%" }}> </td>
          </tr>
          <tr>
            <td style={{ width: "60%" }}>
              Kişisel verilerimin silinmesini ve anonim hale getirilmesini / yok
              edilmesini talep ediyorum.
            </td>
            <td style={{ width: "30%" }}>KVKK m. 11/e</td>
            <td style={{ width: "10%" }}> </td>
          </tr>
          <tr>
            <td style={{ width: "60%" }}>
              Kişisel verilerimin silinmesini ve anonim hale getirilmesini / yok
              edilmesini talep ediyorum.
            </td>
            <td style={{ width: "30%" }}>KVKK m. 11/f</td>
            <td style={{ width: "10%" }}> </td>
          </tr>
          <tr>
            <td style={{ width: "60%" }}>
              Kişisel verilerimin münhasıran otomatik sistemler vasıtasıyla
              analiz edilmesi suretiyle aleyhime bir sonuç doğmasına itiraz
              ediyorum. Analiz edilmesini istemiyorum.
            </td>
            <td style={{ width: "30%" }}>KVKK m. 11/g</td>
            <td style={{ width: "10%" }}> </td>
          </tr>
        </tbody>
      </Table>

      <h3 className="h3 h3-responsive my-5">İMZA</h3>

      {/* <p>
        <a href="/documents/basvuru-formu.doc" download target="_self">
          <span>Başvuru formunu indirmek için tıklayınız</span>
        </a>
      </p> */}
    </>
  );
}

export default ApplicationFormText;
