import { useCallback, useEffect, useRef } from "react";
import { Path, useNavigate } from "react-router-dom";
import useAuthProvider from "./useAuthProvider";

export default function useLogout() {
  const authProvider = useAuthProvider();
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);

  useEffect(() => {
    navigateRef.current = navigate;
  }, [navigate]);

  const logout = useCallback(() => {
    authProvider
      .logout()
      .then((status) => {
        const newLocation: Partial<Path> = {
          pathname: "/auth/signin",
        };
        navigateRef.current(newLocation);
        return status;
      })
      .catch(() => {
        const newLocation: Partial<Path> = {
          pathname: "/auth/signin",
        };
        navigateRef.current(newLocation);
      });
  }, [authProvider]);

  return logout;
}
