import PageHeader from "@otel-lisanslama/components/PageHeader";
import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

const Tariffs: React.FC = () => {
  return (
    <>
      <PageHeader title="Tarifeler" />
      <section className="section">
        <Container>
          <Row>
            <Col md={12}>
              <h5 className="mb-4">2024 Tarifesi</h5>
              <Link
                className="text-primary"
                target="_blank"
                download={true}
                to={
                  process.env.PUBLIC_URL +
                  "/assets/docs/MSF_ve_MUZFED_TARIFE_2024.pdf"
                }
              >
                2024 Müzik Federasyonları Tarifesini İndirmek İçin Tıklayınız
              </Link>
            </Col>
            <Col className="mt-5" md={12}>
              <h5 className="mb-4">2023 Tarifesi</h5>
              <Link
                className="text-primary"
                target="_blank"
                download={true}
                to={
                  process.env.PUBLIC_URL +
                  "/assets/docs/MSF_ve_MUZFED_TARIFE.pdf"
                }
              >
                2023 Müzik Federasyonları Tarifesini İndirmek İçin Tıklayınız
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Tariffs;
