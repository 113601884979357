import "@otel-lisanslama/assets/scss/themes.scss";
import { CsrfProvider } from "@otel-lisanslama/contexts/CsrfContext";
import { useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import "react-datepicker/dist/react-datepicker.css";
import { Link, useLocation } from "react-router-dom";
import { Container } from "reactstrap";
import { AuthProvider } from "./contexts/AuthContext";
import { pageview } from "./libs/gtag";
import AppRoutes from "./routes";
import { dispatch } from "./store";
import { fetchCities } from "./store/slices/location.slice";

function App() {
  const location = useLocation();

  useEffect(() => {
    pageview(location.pathname);
    dispatch(fetchCities(1, 100));
  }, [location]);

  useEffect(() => {
    document.body.setAttribute("data-bs-theme", "light");
    document.body.setAttribute("data-theme", "blue");
  });

  return (
    <CsrfProvider>
      <AuthProvider>
        <AppRoutes />
        <CookieConsent
          location="bottom"
          buttonText="Onaylıyorum"
          cookieName="otel-lisanslama-cookies"
          style={{ background: "#196bbf" }}
          buttonStyle={{
            color: "#111",
            background: "#eee",
            borderRadius: "3px",
          }}
          buttonClasses="btn"
        >
          <Container>
            Web sitemizde size en iyi deneyimi sunabilmemiz için çerezleri
            kullanıyoruz. Bu siteyi kullanmaya devam ederseniz, bunu kabul
            ettiğinizi varsayarız.
            <hr className="border-white" />
            <Link
              className="text-white"
              to="/kisisel-verilerin-korunmasi-ve-islenmesi-politikasi"
            >
              Ayrıntılı Bilgi
            </Link>
          </Container>
        </CookieConsent>
      </AuthProvider>
    </CsrfProvider>
  );
}

export default App;
