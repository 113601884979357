import axios from "@otel-lisanslama/libs/axios";
import { DefaultRootStateProps } from "@otel-lisanslama/types";
import getErrorMessage from "@otel-lisanslama/utils/get-error-message";
import { createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { dispatch } from "..";

const initialState: DefaultRootStateProps["auth"] = {
  data: null,
  error: null,
  isLoading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = getErrorMessage(action.payload, "Bir hata oluştu");
    },
    // LOADING
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    // DATA
    setData(state, action) {
      state.data = action.payload;
    },
    // RESET
    reset(state) {
      state.data = null;
      state.isLoading = false;
      state.error = null;
    },
  },
});

export default authSlice.reducer;

export function resetAuthState() {
  return () => {
    dispatch(authSlice.actions.reset());
  };
}

export function forgotPassword(email: string, token: string) {
  return async () => {
    dispatch(authSlice.actions.hasError(null));
    dispatch(authSlice.actions.setLoading(true));
    try {
      const response = await axios.post("/auth/forgotPassword", {
        email,
        captchaToken: token,
      });
      dispatch(authSlice.actions.setData(response.data));
      dispatch(authSlice.actions.setLoading(false));
      toast.success("E-posta adresinize mail gönderildi");
    } catch (error) {
      dispatch(authSlice.actions.hasError(error));
      dispatch(authSlice.actions.setLoading(false));
    }
  };
}

export function resetPassword(
  token: string,
  password: string,
  password2: string
) {
  return async () => {
    dispatch(authSlice.actions.hasError(null));
    dispatch(authSlice.actions.setLoading(true));
    try {
      const response = await axios.post(`/auth/resetPassword?token=${token}`, {
        password,
        password2,
      });
      dispatch(authSlice.actions.setData(response.data));
      dispatch(authSlice.actions.setLoading(false));
      toast.success("Parolanız sıfırlanmıştır");
    } catch (error) {
      dispatch(authSlice.actions.hasError(error));
      dispatch(authSlice.actions.setLoading(false));
    }
  };
}
